<template>
  <div id="app">
    <half-circle-spinner
      v-show="loading"
      class="app__spinner"
      :animation-duration="1000"
      :size="60"
      color="#0099CC"
    />
    <top-bar
      v-show="!loading && showNav"
      :portal-id="portalId"
      @change-portal="openHSPopup"
    />
    <router-view v-show="!loading" class="router-view" />
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import TopBar from "./components/top-bar/TopBar.component.vue";
import { mapGetters } from "vuex";
export default {
  redirectUrl: "",
  components: {
    TopBar,
    HalfCircleSpinner
  },
  data() {
    return {
      portalId: 0
    };
  },
  computed: {
    ...mapGetters({
      loading: "app/appSpinnerVisible"
    }),
    hsAuthUrl() {
      return `https://app.hubspot.com/oauth/authorize?client_id=d53fe1ec-7bf6-4a55-b0d1-4acf538f1fc1&scope=contacts%20automation%20timeline%20oauth%20integration-sync&redirect_uri=${this.$options.redirectUrl}`;
    },
    showNav() {
      return !this.$route.meta.hideNav;
    }
  },
  created() {
    const redirectUrl = window.location.href.split("?");
    this.$options.redirectUrl = redirectUrl[0];
  },
  async mounted() {
    const hsUser = JSON.parse(sessionStorage.getItem("hsUser"));
    this.portalId = hsUser ? hsUser.portalId : 0;
    const { code } = this.$route.query;
    if (
      code &&
      this.$route.name !== "solution" &&
      this.$route.name !== "auto-install-app" &&
      this.$route.name !== "tray-wizard"
    ) {
      if (opener) {
        opener.location.href = window.location.href;
        window.close();
        return;
      }
      const response = await this.$store.dispatch("hubspot/fetchHsUser", {
        code,
        hsAppId: 180693,
        url: this.$options.redirectUrl
      });
      this.portalId = response.portalId;
    } else {
      if (
        !this.portalId &&
        this.$route.name !== "solution" &&
        this.$route.name !== "auto-install-app" &&
        this.$route.name !== "logger" &&
        this.$route.name !== "solution-instance-logs" &&
        this.$route.name !== "tray-wizard"
      ) {
        this.openHSPopup();
      }
    }
  },
  methods: {
    openHSPopup() {
      if (this.hsPopup == null || this.hsPopup.closed) {
        const w = 1000;
        const h = 600;
        const dualScreenLeft =
          window.screenLeft != undefined ? window.screenLeft : screen.left;
        const dualScreenTop =
          window.screenTop != undefined ? window.screenTop : screen.top;

        const width = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
        const height = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

        const left = width / 2 - w / 2 + dualScreenLeft;
        const top = height / 2 - h / 2 + dualScreenTop;
        this.hsPopup = window.open(
          this.hsAuthUrl,
          "HsAuthPopup",
          "scrollbars,resizable,status,width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
        );
      } else {
        this.hsPopup.focus();
      }
    }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  @import "./styles/global.scss";
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .app__spinner {
    margin: auto;
  }
  .router-view {
    flex: 1;
  }
}
.el-select-dropdown {
  &__item {
    font-family: "Avenir", Helvetica, Arial, sans-serif !important;
    &.selected {
      color: #0099cc !important;
    }
  }
  &__empty {
    font-family: "Avenir", Helvetica, Arial, sans-serif !important;
  }
}
.el-popper,
.el-notification,
.el-message {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
</style>
