<template>
  <el-form
    :model="companyInfo"
    :rules="$options.validationRules.getCompanyInfoFormRules()"
    :validate-on-rule-change="false"
    ref="companyInfoForm"
    class="company-information"
  >
    <form-title>Company information</form-title>
    <div class="row">
      <el-form-item label="Company name*" prop="companyName">
        <el-input
          :autofocus="true"
          v-model="companyInfo.companyName"
        ></el-input>
      </el-form-item>
      <el-form-item label="Website URL*" prop="companyWebsiteUrl">
        <el-input v-model="companyInfo.companyWebsiteUrl"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { validationRules } from "../../utility/validationRules";
import FormTitle from "../ui/FormTitle.component.vue";
export default {
  validationRules,
  components: { FormTitle },
  props: {
    companyData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      companyInfo: {
        companyName: "",
        companyWebsiteUrl: ""
      }
    };
  },
  mounted() {
    this.$refs["companyInfoForm"].resetFields();
    for (let item in this.companyData) {
      this.companyInfo[item] = this.companyData[item];
    }
  },
  methods: {
    validateForm() {
      return this.$refs["companyInfoForm"].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  > div {
    width: 260px;
  }
}
@media (max-width: 630px) {
  .row {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
</style>
