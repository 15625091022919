export const countries = [
  {
    areaCodes: null,
    countryCode: "297",
    countryName: "Aruba",
    countryShort: "AW"
  },
  {
    areaCodes: null,
    countryCode: "93",
    countryName: "Afghanistan",
    countryShort: "AF"
  },
  {
    areaCodes: null,
    countryCode: "244",
    countryName: "Angola",
    countryShort: "AO"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Anguilla",
    countryShort: "AI"
  },
  {
    areaCodes: null,
    countryCode: "358",
    countryName: "Åland Islands",
    countryShort: "AX"
  },
  {
    areaCodes: null,
    countryCode: "355",
    countryName: "Albania",
    countryShort: "AL"
  },
  {
    areaCodes: null,
    countryCode: "376",
    countryName: "Andorra",
    countryShort: "AD"
  },
  {
    areaCodes: null,
    countryCode: "971",
    countryName: "United Arab Emirates",
    countryShort: "AE"
  },
  {
    areaCodes: null,
    countryCode: "54",
    countryName: "Argentina",
    countryShort: "AR"
  },
  {
    areaCodes: null,
    countryCode: "374",
    countryName: "Armenia",
    countryShort: "AM"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "American Samoa",
    countryShort: "AS"
  },
  {
    areaCodes: null,
    countryCode: "672",
    countryName: "Antarctica",
    countryShort: "AQ"
  },
  {
    areaCodes: null,
    countryCode: "262",
    countryName: "French Southern Territories",
    countryShort: "TF"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Antigua and Barbuda",
    countryShort: "AG"
  },
  {
    areaCodes: null,
    countryCode: "61",
    countryName: "Australia",
    countryShort: "AU"
  },
  {
    areaCodes: null,
    countryCode: "43",
    countryName: "Austria",
    countryShort: "AT"
  },
  {
    areaCodes: null,
    countryCode: "994",
    countryName: "Azerbaijan",
    countryShort: "AZ"
  },
  {
    areaCodes: null,
    countryCode: "257",
    countryName: "Burundi",
    countryShort: "BI"
  },
  {
    areaCodes: null,
    countryCode: "32",
    countryName: "Belgium",
    countryShort: "BE"
  },
  {
    areaCodes: null,
    countryCode: "229",
    countryName: "Benin",
    countryShort: "BJ"
  },
  {
    areaCodes: null,
    countryCode: "599",
    countryName: "Bonaire, Sint Eustatius and Saba",
    countryShort: "BQ"
  },
  {
    areaCodes: null,
    countryCode: "226",
    countryName: "Burkina Faso",
    countryShort: "BF"
  },
  {
    areaCodes: null,
    countryCode: "880",
    countryName: "Bangladesh",
    countryShort: "BD"
  },
  {
    areaCodes: null,
    countryCode: "359",
    countryName: "Bulgaria",
    countryShort: "BG"
  },
  {
    areaCodes: null,
    countryCode: "973",
    countryName: "Bahrain",
    countryShort: "BH"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Bahamas",
    countryShort: "BS"
  },
  {
    areaCodes: null,
    countryCode: "387",
    countryName: "Bosnia and Herzegovina",
    countryShort: "BA"
  },
  {
    areaCodes: null,
    countryCode: "590",
    countryName: "Saint Barthélemy",
    countryShort: "BL"
  },
  {
    areaCodes: null,
    countryCode: "375",
    countryName: "Belarus",
    countryShort: "BY"
  },
  {
    areaCodes: null,
    countryCode: "501",
    countryName: "Belize",
    countryShort: "BZ"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Bermuda",
    countryShort: "BM"
  },
  {
    areaCodes: null,
    countryCode: "591",
    countryName: "Bolivia, Plurinational State of",
    countryShort: "BO"
  },
  {
    areaCodes: null,
    countryCode: "55",
    countryName: "Brazil",
    countryShort: "BR"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Barbados",
    countryShort: "BB"
  },
  {
    areaCodes: null,
    countryCode: "673",
    countryName: "Brunei Darussalam",
    countryShort: "BN"
  },
  {
    areaCodes: null,
    countryCode: "975",
    countryName: "Bhutan",
    countryShort: "BT"
  },
  {
    areaCodes: null,
    countryCode: "55",
    countryName: "Bouvet Island",
    countryShort: "BV"
  },
  {
    areaCodes: null,
    countryCode: "267",
    countryName: "Botswana",
    countryShort: "BW"
  },
  {
    areaCodes: null,
    countryCode: "236",
    countryName: "Central African Republic",
    countryShort: "CF"
  },
  {
    areaCodes: [
      {
        stateName: "Alberta",
        stateShort: "AB"
      },
      {
        stateName: "British Columbia",
        stateShort: "BC"
      },
      {
        stateName: "Manitoba",
        stateShort: "MB"
      },
      {
        stateName: "New Brunswick",
        stateShort: "NB"
      },
      {
        stateName: "Newfoundland",
        stateShort: "NL"
      },
      {
        stateName: "Northwest Territories",
        stateShort: "NT"
      },
      {
        stateName: "Nova Scotia",
        stateShort: "NS"
      },
      {
        stateName: "Nunavut",
        stateShort: "NU"
      },
      {
        stateName: "Ontario",
        stateShort: "ON"
      },
      {
        stateName: "Prince Edward Island",
        stateShort: "PE"
      },
      {
        stateName: "Quebec",
        stateShort: "QC"
      },
      {
        stateName: "Saskatchewan",
        stateShort: "SK"
      },
      {
        stateName: "Yukon",
        stateShort: "YT"
      }
    ],
    countryCode: "1",
    countryName: "Canada",
    countryShort: "CA"
  },
  {
    areaCodes: null,
    countryCode: "61",
    countryName: "Cocos (Keeling) Islands",
    countryShort: "CC"
  },
  {
    areaCodes: null,
    countryCode: "41",
    countryName: "Switzerland",
    countryShort: "CH"
  },
  {
    areaCodes: null,
    countryCode: "56",
    countryName: "Chile",
    countryShort: "CL"
  },
  {
    areaCodes: null,
    countryCode: "86",
    countryName: "China",
    countryShort: "CN"
  },
  {
    areaCodes: null,
    countryCode: "225",
    countryName: "Ivory Coast",
    countryShort: "CI"
  },
  {
    areaCodes: null,
    countryCode: "237",
    countryName: "Cameroon",
    countryShort: "CM"
  },
  {
    areaCodes: null,
    countryCode: "243",
    countryName: "Congo, The Democratic Republic of the",
    countryShort: "CD"
  },
  {
    areaCodes: null,
    countryCode: "242",
    countryName: "Congo",
    countryShort: "CG"
  },
  {
    areaCodes: null,
    countryCode: "682",
    countryName: "Cook Islands",
    countryShort: "CK"
  },
  {
    areaCodes: null,
    countryCode: "57",
    countryName: "Colombia",
    countryShort: "CO"
  },
  {
    areaCodes: null,
    countryCode: "269",
    countryName: "Comoros",
    countryShort: "KM"
  },
  {
    areaCodes: null,
    countryCode: "238",
    countryName: "Cabo Verde",
    countryShort: "CV"
  },
  {
    areaCodes: null,
    countryCode: "506",
    countryName: "Costa Rica",
    countryShort: "CR"
  },
  {
    areaCodes: null,
    countryCode: "53",
    countryName: "Cuba",
    countryShort: "CU"
  },
  {
    areaCodes: null,
    countryCode: "599",
    countryName: "Curaçao",
    countryShort: "CW"
  },
  {
    areaCodes: null,
    countryCode: "61",
    countryName: "Christmas Island",
    countryShort: "CX"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Cayman Islands",
    countryShort: "KY"
  },
  {
    areaCodes: null,
    countryCode: "357",
    countryName: "Cyprus",
    countryShort: "CY"
  },
  {
    areaCodes: null,
    countryCode: "420",
    countryName: "Czechia",
    countryShort: "CZ"
  },
  {
    areaCodes: null,
    countryCode: "49",
    countryName: "Germany",
    countryShort: "DE"
  },
  {
    areaCodes: null,
    countryCode: "253",
    countryName: "Djibouti",
    countryShort: "DJ"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Dominica",
    countryShort: "DM"
  },
  {
    areaCodes: null,
    countryCode: "45",
    countryName: "Denmark",
    countryShort: "DK"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Dominican Republic",
    countryShort: "DO"
  },
  {
    areaCodes: null,
    countryCode: "213",
    countryName: "Algeria",
    countryShort: "DZ"
  },
  {
    areaCodes: null,
    countryCode: "593",
    countryName: "Ecuador",
    countryShort: "EC"
  },
  {
    areaCodes: null,
    countryCode: "20",
    countryName: "Egypt",
    countryShort: "EG"
  },
  {
    areaCodes: null,
    countryCode: "291",
    countryName: "Eritrea",
    countryShort: "ER"
  },
  {
    areaCodes: null,
    countryCode: "212",
    countryName: "Western Sahara",
    countryShort: "EH"
  },
  {
    areaCodes: null,
    countryCode: "34",
    countryName: "Spain",
    countryShort: "ES"
  },
  {
    areaCodes: null,
    countryCode: "372",
    countryName: "Estonia",
    countryShort: "EE"
  },
  {
    areaCodes: null,
    countryCode: "251",
    countryName: "Ethiopia",
    countryShort: "ET"
  },
  {
    areaCodes: null,
    countryCode: "358",
    countryName: "Finland",
    countryShort: "FI"
  },
  {
    areaCodes: null,
    countryCode: "679",
    countryName: "Fiji",
    countryShort: "FJ"
  },
  {
    areaCodes: null,
    countryCode: "500",
    countryName: "Falkland Islands (Malvinas)",
    countryShort: "FK"
  },
  {
    areaCodes: null,
    countryCode: "33",
    countryName: "France",
    countryShort: "FR"
  },
  {
    areaCodes: null,
    countryCode: "298",
    countryName: "Faroe Islands",
    countryShort: "FO"
  },
  {
    areaCodes: null,
    countryCode: "691",
    countryName: "Micronesia, Federated States of",
    countryShort: "FM"
  },
  {
    areaCodes: null,
    countryCode: "241",
    countryName: "Gabon",
    countryShort: "GA"
  },
  {
    areaCodes: null,
    countryCode: "44",
    countryName: "United Kingdom",
    countryShort: "GB"
  },
  {
    areaCodes: null,
    countryCode: "995",
    countryName: "Georgia",
    countryShort: "GE"
  },
  {
    areaCodes: null,
    countryCode: "44",
    countryName: "Guernsey",
    countryShort: "GG"
  },
  {
    areaCodes: null,
    countryCode: "233",
    countryName: "Ghana",
    countryShort: "GH"
  },
  {
    areaCodes: null,
    countryCode: "350",
    countryName: "Gibraltar",
    countryShort: "GI"
  },
  {
    areaCodes: null,
    countryCode: "224",
    countryName: "Guinea",
    countryShort: "GN"
  },
  {
    areaCodes: null,
    countryCode: "590",
    countryName: "Guadeloupe",
    countryShort: "GP"
  },
  {
    areaCodes: null,
    countryCode: "220",
    countryName: "Gambia",
    countryShort: "GM"
  },
  {
    areaCodes: null,
    countryCode: "245",
    countryName: "Guinea-Bissau",
    countryShort: "GW"
  },
  {
    areaCodes: null,
    countryCode: "240",
    countryName: "Equatorial Guinea",
    countryShort: "GQ"
  },
  {
    areaCodes: null,
    countryCode: "30",
    countryName: "Greece",
    countryShort: "GR"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Grenada",
    countryShort: "GD"
  },
  {
    areaCodes: null,
    countryCode: "299",
    countryName: "Greenland",
    countryShort: "GL"
  },
  {
    areaCodes: null,
    countryCode: "502",
    countryName: "Guatemala",
    countryShort: "GT"
  },
  {
    areaCodes: null,
    countryCode: "594",
    countryName: "French Guiana",
    countryShort: "GF"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Guam",
    countryShort: "GU"
  },
  {
    areaCodes: null,
    countryCode: "592",
    countryName: "Guyana",
    countryShort: "GY"
  },
  {
    areaCodes: null,
    countryCode: "852",
    countryName: "Hong Kong",
    countryShort: "HK"
  },
  {
    areaCodes: null,
    countryCode: "0",
    countryName: "Heard Island and McDonald Islands",
    countryShort: "HM"
  },
  {
    areaCodes: null,
    countryCode: "504",
    countryName: "Honduras",
    countryShort: "HN"
  },
  {
    areaCodes: null,
    countryCode: "385",
    countryName: "Croatia",
    countryShort: "HR"
  },
  {
    areaCodes: null,
    countryCode: "509",
    countryName: "Haiti",
    countryShort: "HT"
  },
  {
    areaCodes: null,
    countryCode: "36",
    countryName: "Hungary",
    countryShort: "HU"
  },
  {
    areaCodes: null,
    countryCode: "62",
    countryName: "Indonesia",
    countryShort: "ID"
  },
  {
    areaCodes: null,
    countryCode: "44",
    countryName: "Isle of Man",
    countryShort: "IM"
  },
  {
    areaCodes: null,
    countryCode: "91",
    countryName: "India",
    countryShort: "IN"
  },
  {
    areaCodes: null,
    countryCode: "246",
    countryName: "British Indian Ocean Territory",
    countryShort: "IO"
  },
  {
    areaCodes: null,
    countryCode: "353",
    countryName: "Ireland",
    countryShort: "IE"
  },
  {
    areaCodes: null,
    countryCode: "98",
    countryName: "Iran, Islamic Republic of",
    countryShort: "IR"
  },
  {
    areaCodes: null,
    countryCode: "964",
    countryName: "Iraq",
    countryShort: "IQ"
  },
  {
    areaCodes: null,
    countryCode: "354",
    countryName: "Iceland",
    countryShort: "IS"
  },
  {
    areaCodes: null,
    countryCode: "972",
    countryName: "Israel",
    countryShort: "IL"
  },
  {
    areaCodes: null,
    countryCode: "39",
    countryName: "Italy",
    countryShort: "IT"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Jamaica",
    countryShort: "JM"
  },
  {
    areaCodes: null,
    countryCode: "44",
    countryName: "Jersey",
    countryShort: "JE"
  },
  {
    areaCodes: null,
    countryCode: "962",
    countryName: "Jordan",
    countryShort: "JO"
  },
  {
    areaCodes: null,
    countryCode: "81",
    countryName: "Japan",
    countryShort: "JP"
  },
  {
    areaCodes: null,
    countryCode: "7",
    countryName: "Kazakhstan",
    countryShort: "KZ"
  },
  {
    areaCodes: null,
    countryCode: "254",
    countryName: "Kenya",
    countryShort: "KE"
  },
  {
    areaCodes: null,
    countryCode: "996",
    countryName: "Kyrgyzstan",
    countryShort: "KG"
  },
  {
    areaCodes: null,
    countryCode: "855",
    countryName: "Cambodia",
    countryShort: "KH"
  },
  {
    areaCodes: null,
    countryCode: "686",
    countryName: "Kiribati",
    countryShort: "KI"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Saint Kitts and Nevis",
    countryShort: "KN"
  },
  {
    areaCodes: null,
    countryCode: "82",
    countryName: "Korea, Republic of",
    countryShort: "KR"
  },
  {
    areaCodes: null,
    countryCode: "965",
    countryName: "Kuwait",
    countryShort: "KW"
  },
  {
    areaCodes: null,
    countryCode: "856",
    countryName: "Laos",
    countryShort: "LA"
  },
  {
    areaCodes: null,
    countryCode: "961",
    countryName: "Lebanon",
    countryShort: "LB"
  },
  {
    areaCodes: null,
    countryCode: "231",
    countryName: "Liberia",
    countryShort: "LR"
  },
  {
    areaCodes: null,
    countryCode: "218",
    countryName: "Libya",
    countryShort: "LY"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Saint Lucia",
    countryShort: "LC"
  },
  {
    areaCodes: null,
    countryCode: "423",
    countryName: "Liechtenstein",
    countryShort: "LI"
  },
  {
    areaCodes: null,
    countryCode: "94",
    countryName: "Sri Lanka",
    countryShort: "LK"
  },
  {
    areaCodes: null,
    countryCode: "266",
    countryName: "Lesotho",
    countryShort: "LS"
  },
  {
    areaCodes: null,
    countryCode: "370",
    countryName: "Lithuania",
    countryShort: "LT"
  },
  {
    areaCodes: null,
    countryCode: "352",
    countryName: "Luxembourg",
    countryShort: "LU"
  },
  {
    areaCodes: null,
    countryCode: "371",
    countryName: "Latvia",
    countryShort: "LV"
  },
  {
    areaCodes: null,
    countryCode: "853",
    countryName: "Macao",
    countryShort: "MO"
  },
  {
    areaCodes: null,
    countryCode: "590",
    countryName: "Saint Martin (French part)",
    countryShort: "MF"
  },
  {
    areaCodes: null,
    countryCode: "212",
    countryName: "Morocco",
    countryShort: "MA"
  },
  {
    areaCodes: null,
    countryCode: "377",
    countryName: "Monaco",
    countryShort: "MC"
  },
  {
    areaCodes: null,
    countryCode: "373",
    countryName: "Moldova, Republic of",
    countryShort: "MD"
  },
  {
    areaCodes: null,
    countryCode: "261",
    countryName: "Madagascar",
    countryShort: "MG"
  },
  {
    areaCodes: null,
    countryCode: "960",
    countryName: "Maldives",
    countryShort: "MV"
  },
  {
    areaCodes: null,
    countryCode: "52",
    countryName: "Mexico",
    countryShort: "MX"
  },
  {
    areaCodes: null,
    countryCode: "692",
    countryName: "Marshall Islands",
    countryShort: "MH"
  },
  {
    areaCodes: null,
    countryCode: "389",
    countryName: "Macedonia, Republic of",
    countryShort: "MK"
  },
  {
    areaCodes: null,
    countryCode: "223",
    countryName: "Mali",
    countryShort: "ML"
  },
  {
    areaCodes: null,
    countryCode: "356",
    countryName: "Malta",
    countryShort: "MT"
  },
  {
    areaCodes: null,
    countryCode: "95",
    countryName: "Myanmar",
    countryShort: "MM"
  },
  {
    areaCodes: null,
    countryCode: "382",
    countryName: "Montenegro",
    countryShort: "ME"
  },
  {
    areaCodes: null,
    countryCode: "976",
    countryName: "Mongolia",
    countryShort: "MN"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Northern Mariana Islands",
    countryShort: "MP"
  },
  {
    areaCodes: null,
    countryCode: "258",
    countryName: "Mozambique",
    countryShort: "MZ"
  },
  {
    areaCodes: null,
    countryCode: "222",
    countryName: "Mauritania",
    countryShort: "MR"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Montserrat",
    countryShort: "MS"
  },
  {
    areaCodes: null,
    countryCode: "596",
    countryName: "Martinique",
    countryShort: "MQ"
  },
  {
    areaCodes: null,
    countryCode: "230",
    countryName: "Mauritius",
    countryShort: "MU"
  },
  {
    areaCodes: null,
    countryCode: "265",
    countryName: "Malawi",
    countryShort: "MW"
  },
  {
    areaCodes: null,
    countryCode: "60",
    countryName: "Malaysia",
    countryShort: "MY"
  },
  {
    areaCodes: null,
    countryCode: "262",
    countryName: "Mayotte",
    countryShort: "YT"
  },
  {
    areaCodes: null,
    countryCode: "264",
    countryName: "Namibia",
    countryShort: "NA"
  },
  {
    areaCodes: null,
    countryCode: "687",
    countryName: "New Caledonia",
    countryShort: "NC"
  },
  {
    areaCodes: null,
    countryCode: "227",
    countryName: "Niger",
    countryShort: "NE"
  },
  {
    areaCodes: null,
    countryCode: "672",
    countryName: "Norfolk Island",
    countryShort: "NF"
  },
  {
    areaCodes: null,
    countryCode: "234",
    countryName: "Nigeria",
    countryShort: "NG"
  },
  {
    areaCodes: null,
    countryCode: "505",
    countryName: "Nicaragua",
    countryShort: "NI"
  },
  {
    areaCodes: null,
    countryCode: "683",
    countryName: "Niue",
    countryShort: "NU"
  },
  {
    areaCodes: null,
    countryCode: "31",
    countryName: "Netherlands",
    countryShort: "NL"
  },
  {
    areaCodes: null,
    countryCode: "47",
    countryName: "Norway",
    countryShort: "NO"
  },
  {
    areaCodes: null,
    countryCode: "977",
    countryName: "Nepal",
    countryShort: "NP"
  },
  {
    areaCodes: null,
    countryCode: "674",
    countryName: "Nauru",
    countryShort: "NR"
  },
  {
    areaCodes: null,
    countryCode: "64",
    countryName: "New Zealand",
    countryShort: "NZ"
  },
  {
    areaCodes: null,
    countryCode: "968",
    countryName: "Oman",
    countryShort: "OM"
  },
  {
    areaCodes: null,
    countryCode: "92",
    countryName: "Pakistan",
    countryShort: "PK"
  },
  {
    areaCodes: null,
    countryCode: "507",
    countryName: "Panama",
    countryShort: "PA"
  },
  {
    areaCodes: null,
    countryCode: "64",
    countryName: "Pitcairn",
    countryShort: "PN"
  },
  {
    areaCodes: null,
    countryCode: "51",
    countryName: "Peru",
    countryShort: "PE"
  },
  {
    areaCodes: null,
    countryCode: "63",
    countryName: "Philippines",
    countryShort: "PH"
  },
  {
    areaCodes: null,
    countryCode: "680",
    countryName: "Palau",
    countryShort: "PW"
  },
  {
    areaCodes: null,
    countryCode: "675",
    countryName: "Papua New Guinea",
    countryShort: "PG"
  },
  {
    areaCodes: null,
    countryCode: "48",
    countryName: "Poland",
    countryShort: "PL"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Puerto Rico",
    countryShort: "PR"
  },
  {
    areaCodes: null,
    countryCode: "850",
    countryName: "North Korea",
    countryShort: "KP"
  },
  {
    areaCodes: null,
    countryCode: "351",
    countryName: "Portugal",
    countryShort: "PT"
  },
  {
    areaCodes: null,
    countryCode: "595",
    countryName: "Paraguay",
    countryShort: "PY"
  },
  {
    areaCodes: null,
    countryCode: "970",
    countryName: "Palestine, State of",
    countryShort: "PS"
  },
  {
    areaCodes: null,
    countryCode: "689",
    countryName: "French Polynesia",
    countryShort: "PF"
  },
  {
    areaCodes: null,
    countryCode: "974",
    countryName: "Qatar",
    countryShort: "QA"
  },
  {
    areaCodes: null,
    countryCode: "262",
    countryName: "Réunion",
    countryShort: "RE"
  },
  {
    areaCodes: null,
    countryCode: "40",
    countryName: "Romania",
    countryShort: "RO"
  },
  {
    areaCodes: null,
    countryCode: "7",
    countryName: "Russian Federation",
    countryShort: "RU"
  },
  {
    areaCodes: null,
    countryCode: "250",
    countryName: "Rwanda",
    countryShort: "RW"
  },
  {
    areaCodes: null,
    countryCode: "966",
    countryName: "Saudi Arabia",
    countryShort: "SA"
  },
  {
    areaCodes: null,
    countryCode: "249",
    countryName: "Sudan",
    countryShort: "SD"
  },
  {
    areaCodes: null,
    countryCode: "221",
    countryName: "Senegal",
    countryShort: "SN"
  },
  {
    areaCodes: null,
    countryCode: "65",
    countryName: "Singapore",
    countryShort: "SG"
  },
  {
    areaCodes: null,
    countryCode: "500",
    countryName: "South Georgia and the South Sandwich Islands",
    countryShort: "GS"
  },
  {
    areaCodes: null,
    countryCode: "290",
    countryName: "Saint Helena, Ascension and Tristan da Cunha",
    countryShort: "SH"
  },
  {
    areaCodes: null,
    countryCode: "47",
    countryName: "Svalbard and Jan Mayen",
    countryShort: "SJ"
  },
  {
    areaCodes: null,
    countryCode: "677",
    countryName: "Solomon Islands",
    countryShort: "SB"
  },
  {
    areaCodes: null,
    countryCode: "232",
    countryName: "Sierra Leone",
    countryShort: "SL"
  },
  {
    areaCodes: null,
    countryCode: "503",
    countryName: "El Salvador",
    countryShort: "SV"
  },
  {
    areaCodes: null,
    countryCode: "378",
    countryName: "San Marino",
    countryShort: "SM"
  },
  {
    areaCodes: null,
    countryCode: "252",
    countryName: "Somalia",
    countryShort: "SO"
  },
  {
    areaCodes: null,
    countryCode: "508",
    countryName: "Saint Pierre and Miquelon",
    countryShort: "PM"
  },
  {
    areaCodes: null,
    countryCode: "381",
    countryName: "Serbia",
    countryShort: "RS"
  },
  {
    areaCodes: null,
    countryCode: "211",
    countryName: "South Sudan",
    countryShort: "SS"
  },
  {
    areaCodes: null,
    countryCode: "239",
    countryName: "Sao Tome and Principe",
    countryShort: "ST"
  },
  {
    areaCodes: null,
    countryCode: "597",
    countryName: "Suriname",
    countryShort: "SR"
  },
  {
    areaCodes: null,
    countryCode: "421",
    countryName: "Slovakia",
    countryShort: "SK"
  },
  {
    areaCodes: null,
    countryCode: "386",
    countryName: "Slovenia",
    countryShort: "SI"
  },
  {
    areaCodes: null,
    countryCode: "46",
    countryName: "Sweden",
    countryShort: "SE"
  },
  {
    areaCodes: null,
    countryCode: "268",
    countryName: "Swaziland",
    countryShort: "SZ"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Sint Maarten (Dutch part)",
    countryShort: "SX"
  },
  {
    areaCodes: null,
    countryCode: "248",
    countryName: "Seychelles",
    countryShort: "SC"
  },
  {
    areaCodes: null,
    countryCode: "963",
    countryName: "Syrian Arab Republic",
    countryShort: "SY"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Turks and Caicos Islands",
    countryShort: "TC"
  },
  {
    areaCodes: null,
    countryCode: "235",
    countryName: "Chad",
    countryShort: "TD"
  },
  {
    areaCodes: null,
    countryCode: "228",
    countryName: "Togo",
    countryShort: "TG"
  },
  {
    areaCodes: null,
    countryCode: "66",
    countryName: "Thailand",
    countryShort: "TH"
  },
  {
    areaCodes: null,
    countryCode: "992",
    countryName: "Tajikistan",
    countryShort: "TJ"
  },
  {
    areaCodes: null,
    countryCode: "690",
    countryName: "Tokelau",
    countryShort: "TK"
  },
  {
    areaCodes: null,
    countryCode: "993",
    countryName: "Turkmenistan",
    countryShort: "TM"
  },
  {
    areaCodes: null,
    countryCode: "670",
    countryName: "Timor-Leste",
    countryShort: "TL"
  },
  {
    areaCodes: null,
    countryCode: "676",
    countryName: "Tonga",
    countryShort: "TO"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Trinidad and Tobago",
    countryShort: "TT"
  },
  {
    areaCodes: null,
    countryCode: "216",
    countryName: "Tunisia",
    countryShort: "TN"
  },
  {
    areaCodes: null,
    countryCode: "90",
    countryName: "Turkey",
    countryShort: "TR"
  },
  {
    areaCodes: null,
    countryCode: "688",
    countryName: "Tuvalu",
    countryShort: "TV"
  },
  {
    areaCodes: null,
    countryCode: "886",
    countryName: "Taiwan, Province of China",
    countryShort: "TW"
  },
  {
    areaCodes: null,
    countryCode: "255",
    countryName: "Tanzania, United Republic of",
    countryShort: "TZ"
  },
  {
    areaCodes: null,
    countryCode: "256",
    countryName: "Uganda",
    countryShort: "UG"
  },
  {
    areaCodes: null,
    countryCode: "380",
    countryName: "Ukraine",
    countryShort: "UA"
  },
  {
    areaCodes: null,
    countryCode: "246",
    countryName: "United States Minor Outlying Islands",
    countryShort: "UM"
  },
  {
    areaCodes: null,
    countryCode: "598",
    countryName: "Uruguay",
    countryShort: "UY"
  },
  {
    areaCodes: [
      {
        stateName: "Alabama",
        stateShort: "AL"
      },
      {
        stateName: "Alaska",
        stateShort: "AK"
      },
      {
        stateName: "American Samoa",
        stateShort: "AS"
      },
      {
        stateName: "Arizona",
        stateShort: "AZ"
      },
      {
        stateName: "Arkansas",
        stateShort: "AR"
      },
      {
        stateName: "California",
        stateShort: "CA"
      },
      {
        stateName: "Colorado",
        stateShort: "CO"
      },
      {
        stateName: "Connecticut",
        stateShort: "CT"
      },
      {
        stateName: "Delaware",
        stateShort: "DE"
      },
      {
        stateName: "Florida",
        stateShort: "FL"
      },
      {
        stateName: "Georgia",
        stateShort: "GA"
      },
      {
        stateName: "Guam",
        stateShort: "GU"
      },
      {
        stateName: "Hawaii",
        stateShort: "HI"
      },
      {
        stateName: "Idaho",
        stateShort: "ID"
      },
      {
        stateName: "Illinois",
        stateShort: "IL"
      },
      {
        stateName: "Indiana",
        stateShort: "IN"
      },
      {
        stateName: "Iowa",
        stateShort: "IA"
      },
      {
        stateName: "Kansas",
        stateShort: "KS"
      },
      {
        stateName: "Kentucky",
        stateShort: "KY"
      },
      {
        stateName: "Louisiana",
        stateShort: "LA"
      },
      {
        stateName: "Maine",
        stateShort: "ME"
      },
      {
        stateName: "Maryland",
        stateShort: "MD"
      },
      {
        stateName: "Massachusetts",
        stateShort: "MA"
      },
      {
        stateName: "Michigan",
        stateShort: "MI"
      },
      {
        stateName: "Minnesota",
        stateShort: "MN"
      },
      {
        stateName: "Mississippi",
        stateShort: "MS"
      },
      {
        stateName: "Missouri",
        stateShort: "MO"
      },
      {
        stateName: "Montana",
        stateShort: "MT"
      },
      {
        stateName: "Nebraska",
        stateShort: "NE"
      },
      {
        stateName: "Nevada",
        stateShort: "NV"
      },
      {
        stateName: "New Hampshire",
        stateShort: "NH"
      },
      {
        stateName: "New Jersey",
        stateShort: "NJ"
      },
      {
        stateName: "New Mexico",
        stateShort: "NM"
      },
      {
        stateName: "New York",
        stateShort: "NY"
      },
      {
        stateName: "North Carolina",
        stateShort: "NC"
      },
      {
        stateName: "North Dakota",
        stateShort: "ND"
      },
      {
        stateName: "Northern Mariana Islands",
        stateShort: "MP"
      },
      {
        stateName: "Ohio",
        stateShort: "OH"
      },
      {
        stateName: "Oklahoma",
        stateShort: "OK"
      },
      {
        stateName: "Oregon",
        stateShort: "OR"
      },
      {
        stateName: "Pennsylvania",
        stateShort: "PA"
      },
      {
        stateName: "Puerto Rico",
        stateShort: "PR"
      },
      {
        stateName: "Rhode Island",
        stateShort: "RI"
      },
      {
        stateName: "South Carolina",
        stateShort: "SC"
      },
      {
        stateName: "South Dakota",
        stateShort: "SD"
      },
      {
        stateName: "Tennessee",
        stateShort: "TN"
      },
      {
        stateName: "Texas",
        stateShort: "TX"
      },
      {
        stateName: "Utah",
        stateShort: "UT"
      },
      {
        stateName: "Vermont",
        stateShort: "VT"
      },
      {
        stateName: "Virgin Islands",
        stateShort: "VI"
      },
      {
        stateName: "Virginia",
        stateShort: "VA"
      },
      {
        stateName: "Washington",
        stateShort: "WA"
      },
      {
        stateName: "Washington, DC",
        stateShort: "DC"
      },
      {
        stateName: "West Virginia",
        stateShort: "WV"
      },
      {
        stateName: "Wisconsin",
        stateShort: "WI"
      },
      {
        stateName: "Wyoming",
        stateShort: "WY"
      }
    ],
    countryCode: "1",
    countryName: "United States",
    countryShort: "US"
  },
  {
    areaCodes: null,
    countryCode: "998",
    countryName: "Uzbekistan",
    countryShort: "UZ"
  },
  {
    areaCodes: null,
    countryCode: "39",
    countryName: "Holy See (Vatican City State)",
    countryShort: "VA"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Saint Vincent and the Grenadines",
    countryShort: "VC"
  },
  {
    areaCodes: null,
    countryCode: "58",
    countryName: "Venezuela, Bolivarian Republic of",
    countryShort: "VE"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Virgin Islands, British",
    countryShort: "VG"
  },
  {
    areaCodes: null,
    countryCode: "1",
    countryName: "Virgin Islands, U.S.",
    countryShort: "VI"
  },
  {
    areaCodes: null,
    countryCode: "84",
    countryName: "Viet Nam",
    countryShort: "VN"
  },
  {
    areaCodes: null,
    countryCode: "678",
    countryName: "Vanuatu",
    countryShort: "VU"
  },
  {
    areaCodes: null,
    countryCode: "681",
    countryName: "Wallis and Futuna",
    countryShort: "WF"
  },
  {
    areaCodes: null,
    countryCode: "685",
    countryName: "Samoa",
    countryShort: "WS"
  },
  {
    areaCodes: null,
    countryCode: "967",
    countryName: "Yemen",
    countryShort: "YE"
  },
  {
    areaCodes: null,
    countryCode: "27",
    countryName: "South Africa",
    countryShort: "ZA"
  },
  {
    areaCodes: null,
    countryCode: "260",
    countryName: "Zambia",
    countryShort: "ZM"
  },
  {
    areaCodes: null,
    countryCode: "263",
    countryName: "Zimbabwe",
    countryShort: "ZW"
  },
  {
    areaCodes: null,
    countryCode: "383",
    countryName: "Kosovo",
    countryShort: "XK"
  },
  {
    areaCodes: null,
    countryCode: "247",
    countryName: "Ascension Island*",
    countryShort: "AC"
  }
];
