var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"install-app"},[_c('div',{staticClass:"install-app__header"},[_vm._m(0),(_vm.showInstallationHeader)?_c('div',{staticClass:"install-app__header--integration-logo-wrapper"},[_c('img',{attrs:{"src":_vm.integrationImages.hubspot,"height":"40","width":"122","alt":""}}),_c('img',{attrs:{"src":require("../assets/images/plus.svg"),"id":"plus-img","height":"14","width":"14","alt":""}}),_c('img',{attrs:{"src":_vm.integrationImages.solution,"height":"40","alt":""}})]):_vm._e(),(
        _vm.activeStep !== 5 &&
          _vm.showInstallationHeader &&
          !_vm.preinstallConfigCodePresent
      )?_c('form-steps',{staticClass:"install-app__header--steps",attrs:{"number-of-steps":4,"active-step":_vm.activeStep}}):_vm._e(),(_vm.activeStep !== 5 && _vm.showInstallationHeader && !_vm.removeTitle)?_c('h2',{staticClass:"install-app__header--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('div',{staticClass:"install-app__steps-wrapper",class:_vm.$route.name},[_c('router-view',{attrs:{"install-url":_vm.iframeUrl,"tray-config-ready":_vm.trayConfigReady,"company-and-user-data":{
        user: Object.assign({}, _vm.stepsData.userInfo),
        company: Object.assign({}, _vm.stepsData.companyInfo)
      },"billing-plan":_vm.stepsData.billingPlan,"billing-step-data":_vm.billingStepData,"logger-link-data":_vm.loggerLinkData,"solution-id":_vm.solutionId},on:{"company-and-user-data-step-finished":_vm.companyAndUserInfoStepFinishedHandler,"billing-plans-step-finished":_vm.billingPlansStepFinishedHandler,"billing-info-step-finished":_vm.billingInfoStepFinishedHandler,"fetch-install-url":_vm.getInstallUrl,"save-billing-additional-data":_vm.saveBillingAdditionalDataHandler,"trigger-workflows":_vm.triggerWorkflows}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"install-app__header--lynton-logo-wrapper"},[_c('img',{attrs:{"src":require("../assets/images/syncsmart-logo.svg"),"height":"42","width":"177","alt":""}})])}]

export { render, staticRenderFns }