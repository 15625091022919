<template>
  <div class="install-app__steps-wrapper--step">
    <error-step />
  </div>
</template>

<script>
import ErrorStep from "../error-screen/ErrorScreen.component.vue";
export default {
  components: {
    ErrorStep
  }
};
</script>
