import { hubspotService } from "./../../../services/hubspotService";
import { hubspotFormsService } from "../../../services/hubspotFormsService";

const fetchHsUser = async (context, data) => {
  const { code, hsAppId, url } = data;
  // if (context.getters.data.length === 0) {
  // context.commit("SET_LOADING", true);
  const response = await hubspotService.hsUser(code, hsAppId, url);
  // context.commit("SET_DATA", response);
  return response;
  // context.commit("SET_LOADING", false);
  // }
};

const postHsForm = (context, data) => {
  hubspotFormsService.postForm(data);
};

export default {
  fetchHsUser,
  postHsForm
};
