import { publicPost } from "../shared/api";
import { HsUser } from "../models/HsUser";
import { config } from "../config/index";

const baseUrl = config.getBaseUrl();

export const hubspotService = {
  async hsUser(code, hubspotAppId, redirectUrl) {
    const url = `${baseUrl}/api/v1/auth/proxy/token/`;
    const data = {
      code,
      hubspot_app_id: hubspotAppId,
      redirect_url: redirectUrl
    };
    try {
      const response = await publicPost(url, data);
      return new HsUser(response.data);
    } catch (error) {
      throw error.response.data.error;
    }
  },
  async hsToken(code, hubspotAppId, redirectUrl) {
    const url = `${baseUrl}/api/v1/auth/proxy/token/`;
    const data = {
      code,
      hubspot_app_id: hubspotAppId,
      redirect_url: redirectUrl
    };
    const response = await publicPost(url, data);
    return response;
  }
};
