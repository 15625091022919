<template>
  <el-form
    :model="userInfo"
    :rules="$options.validationRules.getUserInfoFormRules()"
    :validate-on-rule-change="false"
    ref="userInfoForm"
    class="user-information"
  >
    <form-title>User information</form-title>
    <div class="row">
      <el-form-item label="First name*" prop="firstName">
        <el-input :autofocus="true" v-model="userInfo.firstName"></el-input>
      </el-form-item>
      <el-form-item label="Last name*" prop="lastName">
        <el-input v-model="userInfo.lastName"></el-input>
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item label="Email address*" prop="email">
        <el-input :autofocus="true" v-model="userInfo.email"></el-input>
      </el-form-item>
      <el-form-item label="Job Role*" prop="role">
        <el-input v-model="userInfo.role"></el-input>
      </el-form-item>
      <!-- <el-form-item label="Mobile Phone*" prop="phone">
        <el-input v-model="userInfo.phone"></el-input>
      </el-form-item>-->
    </div>
  </el-form>
</template>

<script>
import { validationRules } from "../../utility/validationRules";
import FormTitle from "../ui/FormTitle.component.vue";
export default {
  validationRules,
  components: { FormTitle },
  props: {
    userData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        // phone: "",
        role: ""
      }
    };
  },
  mounted() {
    this.$refs["userInfoForm"].resetFields();
    for (let item in this.userData) {
      this.userInfo[item] = this.userData[item];
    }
  },
  methods: {
    validateForm() {
      return this.$refs["userInfoForm"].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  > div {
    width: 260px;
  }
}
@media (max-width: 630px) {
  .row {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
</style>
