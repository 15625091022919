<template>
  <div class="form-steps">
    <div
      v-for="step in numberOfSteps"
      :key="step"
      class="form-steps__step"
      :class="[
        { active: step === activeStep },
        { 'not-passed': step > activeStep }
      ]"
    >
      <div class="form-steps__step--dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfSteps: {
      type: Number,
      required: true,
      default: 1
    },
    activeStep: {
      type: Number,
      required: true,
      default: 1
    }
  }
};
</script>

<style lang="scss" scoped>
.form-steps {
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__step {
    flex: 1;
    display: flex;
    align-items: center;
    &--dot {
      width: 10px;
      height: 10px;
      background-color: #ffffff;
      border-radius: 50%;
    }
    &:before {
      content: "";
      display: block;
      flex: 1;
      height: 1px;
      background-color: #ffffff;
    }
    &:after {
      content: "";
      display: block;
      flex: 1;
      height: 1px;
      background-color: #ffffff;
    }
    &:first-of-type {
      &:before {
        visibility: hidden;
      }
    }
    &:last-of-type {
      &:after {
        visibility: hidden;
      }
    }
    &.active {
      &:after {
        opacity: 0.7;
      }
      .form-steps__step--dot {
        width: 22px;
        height: 22px;
        border: 6px solid #283df2;
        box-shadow: 0px 0px 0px 1px #ffffff;
      }
    }
    &.not-passed {
      &:after,
      &:before,
      .form-steps__step--dot {
        opacity: 0.7;
      }
    }
  }
}
@media (max-width: 400px) {
  .form-steps {
    width: 100%;
  }
}
</style>
