const initApp = async context => {
  try {
    await context.dispatch("app/setAppSpinnerVisible", true);
    await context.dispatch("billing/fetchData");
    await context.dispatch("app/setAppSpinnerVisible", false);
  } catch (error) {
    throw error;
  }
};

export default {
  initApp
};
