<template>
  <div class="solution-instance-logger">
    <div class="solution-instance-logger__header">
      <div class="solution-instance-logger__header--lynton-logo-wrapper">
        <img
          src="../assets/images/syncsmart-logo.svg"
          height="42"
          width="177"
          alt
        />
      </div>
    </div>
    <logger :portal-id="portalId" />
  </div>
</template>

<script>
import Logger from "./Logger.vue";
export default {
  components: {
    Logger
  },
  computed: {
    portalId() {
      return +this.$route.params.portalId;
    }
  }
};
</script>

<style lang="scss" scoped>
.solution-instance-logger {
  &__header {
    &--lynton-logo-wrapper {
      height: 70px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
