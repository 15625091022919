import { solutionsService } from "./../../../services/solutionsService";

const fetchData = async context => {
  if (context.getters.data.length === 0) {
    context.commit("SET_LOADING", true);
    const data = await solutionsService.getAllSolutions();
    context.commit("SET_DATA", data);
    context.commit("SET_LOADING", false);
  }
};

const fetchSolutionData = async (context, id) => {
  await context.dispatch("fetchData");
  return context.getters.data.find(item => item.id === id);
};

const createUserSolution = async (context, { token, id, preinstallCode }) => {
  context.commit("SET_LOADING", true);
  try {
    const response = await solutionsService.createUserSolution(
      id,
      token,
      preinstallCode
    );
    let solutionId = null;
    if (response.status === "ok") {
      solutionId = response.data.id;
    } else {
      solutionId = response.data.solution.id;
    }
    context.commit("SET_LOADING", false);
    return {
      solutionInstanceId: solutionId,
      installOptions: response.data.options
    };
  } catch (error) {
    throw error;
  }
};

const fetchInstallUrl = async (context, { token, solutionInstanceId }) => {
  context.commit("SET_LOADING", true);
  try {
    const solutionId = solutionInstanceId;
    const configLink = await solutionsService.createOnboardingLink(
      solutionId,
      token
    );
    context.commit("SET_LOADING", false);
    return { configLink };
  } catch (error) {
    throw error;
  }
};

const getTokenFromPreinstallCode = async (context, preinstallCode) => {
  context.commit("SET_LOADING", true);
  try {
    const response = await solutionsService.getTokenFromPreinstallCode(
      preinstallCode
    );
    if (response) {
      return response;
    }
    context.commit("SET_LOADING", false);
  } catch (error) {
    throw error.response;
  }
};

export default {
  fetchData,
  fetchSolutionData,
  createUserSolution,
  fetchInstallUrl,
  getTokenFromPreinstallCode
};
