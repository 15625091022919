const SET_LOADING = (state, value) => {
  state.loading = value;
};

const SET_DATA = (state, value) => {
  state.data = value;
};

const SET_SOLUTION_DATA = (state, value) => {
  state.solutionPlansData = value;
};

const SET_BILLING_RESPONSE_DATA = (state, value) => {
  state.billingResponseData = value;
};

export default {
  SET_LOADING,
  SET_DATA,
  SET_SOLUTION_DATA,
  SET_BILLING_RESPONSE_DATA
};
