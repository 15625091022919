<template>
  <div class="install-app__steps-wrapper--step">
    <company-info-form
      ref="companyInfoForm"
      :company-data="companyAndUserData.company"
    />
    <user-info-form :user-data="companyAndUserData.user" ref="userInfoForm" />
    <el-button @click="validateCompanyAndUserDataHandler">Proceed</el-button>
  </div>
</template>

<script>
import CompanyInfoForm from "../../company-info/CompanyInfoForm.component.vue";
import UserInfoForm from "../../user-info/UserInfoForm.component.vue";
import { installDataStorage } from "../../../utility/marketplaceSessionStorage";
export default {
  components: {
    CompanyInfoForm,
    UserInfoForm
  },
  props: {
    companyAndUserData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    solutionId: {
      type: String,
      requred: true,
      default: ""
    }
  },
  beforeCreate() {
    const { skipUserRegistration } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (skipUserRegistration) {
      this.$router.push({ name: "step-2" });
    }
  },
  methods: {
    async validateCompanyAndUserDataHandler() {
      const companyDataValid = await this.$refs.companyInfoForm
        .validateForm()
        .catch(e => {
          return e;
        });
      const userDataValid = await this.$refs.userInfoForm
        .validateForm()
        .catch(e => {
          return e;
        });
      if (companyDataValid && userDataValid) {
        this.$emit("company-and-user-data-step-finished", {
          user: this.$refs.userInfoForm.userInfo,
          company: this.$refs.companyInfoForm.companyInfo
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-information {
  margin-top: 10px;
}
.el-button {
  width: 100%;
  margin-top: 40px;
}
</style>
