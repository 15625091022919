<template>
  <div class="install-app-error-screen">
    <form-title>Oops, something went wrong.</form-title>
    <p class="install-app-error-screen__text">
      Please refresh the page and try again or contact us at
      <a href="mailto:support@lyntonweb.com" target="_top"
        >support@lyntonweb.com</a
      >
    </p>
    <el-button class="install-app-error-screen__btn" @click="refresh"
      >Refresh</el-button
    >
  </div>
</template>

<script>
import FormTitle from "../../ui/FormTitle.component.vue";
export default {
  components: { FormTitle },
  methods: {
    refresh() {
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.install-app-error-screen {
  padding: 250px 60px 80px;
  background-image: url("../../../assets/images/error-img.svg");
  background-repeat: no-repeat;
  background-position: center 70px;
  &__text {
    color: #6b7d90;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    a {
      color: #0099cc;
      text-decoration: none;
    }
  }
  &__btn {
    margin-top: 20px;
  }
}
@media (max-width: 630px) {
  .install-app-error-screen {
    padding: 180px 20px 40px;
    background-position: center 20px;
    &__text {
      margin-top: 20px;
    }
  }
}
</style>
