const env_vars = Object.assign(process.env, window.$env);

export const config = {
  stripeKey() {
    return this.isProduction()
      ? "pk_live_QXwGLYYWbnVJZNKI8wWiemkW00zcQMozgF"
      : "pk_test_QMGaMgornVokjbfoUWLmAL2u00u0WH2HWi";
  },
  isProduction() {
    return env_vars.VUE_APP_IS_PRODUCTION === "TRUE";
  },
  getBaseUrl() {
    return (
      env_vars.VUE_APP_BACKEND_BASE_URL || process.env.VUE_APP_BACKEND_BASE_URL
    );
  }
};
