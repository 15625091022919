const defaultRequiredFieldValidation = [
  { required: true, message: "Required field", trigger: ["blur", "change"] }
];

const defaultUrlValidation = [
  {
    pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    message: "Please provide valid URL",
    trigger: ["blur", "change"]
  }
];

const usaPhoneValidation = [
  {
    pattern: /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/,
    message: "Please provide valid US phone number",
    trigger: ["blur", "change"]
  }
];

const defaultPhoneValidation = [
  {
    pattern: /^\d+$/,
    message: "Phone number must contain digits only",
    trigger: ["blur", "change"]
  },
  {
    min: 5,
    message: "Phone number must be at least 5 digits",
    trigger: ["blur", "change"]
  },
  {
    max: 15,
    message: "Phone number can't be longer than 15 digits",
    trigger: ["blur", "change"]
  }
];

const defaultZipCodeValidation = [
  {
    min: 3,
    message: "Zip Code must be minimum 3 characters long",
    trigger: ["blur", "change"]
  },
  {
    max: 7,
    message: "Zip Code must be maximum 7 characters long",
    trigger: ["blur", "change"]
  }
  // { pattern: /^\d+$/, message: 'Please provide valid zip code.', trigger: ['blur', 'change']},
  // { min: 5, message: 'Zip Code must be 5 digits', trigger: ['blur', 'change']},
  // { max: 5, message: 'Zip Code must be 5 digits', trigger: ['blur', 'change']}
];

// const alphanumericValidation = [
//   {
//     pattern: /^[a-zA-Z0-9]+$/,
//     message: "Please provide valid alphanumeric value.",
//     trigger: ["blur", "change"]
//   },
//   {
//     min: 5,
//     message: "Alphanumeric value must be minimum 5 characters long.",
//     trigger: ["blur", "change"]
//   },
//   {
//     max: 11,
//     message: "Alphanumeric value must be maximum 11 characters long.",
//     trigger: ["blur", "change"]
//   }
// ];

const validationRules = {
  getCompanyInfoFormRules() {
    return {
      companyName: [...defaultRequiredFieldValidation],
      companyWebsiteUrl: [
        ...defaultUrlValidation,
        ...defaultRequiredFieldValidation
      ]
    };
  },
  getBillingFormRules(data) {
    const phoneValidation =
      data.phone === "US" ? usaPhoneValidation : defaultPhoneValidation;
    const stateValidation = data.state ? defaultRequiredFieldValidation : [];
    return {
      name: [...defaultRequiredFieldValidation],
      addressLine1: [...defaultRequiredFieldValidation],
      addressCity: [...defaultRequiredFieldValidation],
      addressState: [...stateValidation],
      addressCountry: [...defaultRequiredFieldValidation],
      addressZip: [
        ...defaultRequiredFieldValidation,
        ...defaultZipCodeValidation
      ],
      phone: [...defaultRequiredFieldValidation, ...phoneValidation]
    };
  },
  getUserInfoFormRules() {
    return {
      firstName: [...defaultRequiredFieldValidation],
      lastName: [...defaultRequiredFieldValidation],
      role: [...defaultRequiredFieldValidation],
      email: [
        {
          required: true,
          message: "Required field",
          trigger: "blur"
        },
        {
          type: "email",
          message: "Please enter valid emial address",
          trigger: ["blur", "change"]
        }
      ],
      phone: [...defaultRequiredFieldValidation, ...defaultPhoneValidation]
    };
  }
};

export { validationRules };
