import { publicGet, publicPost, post, get } from "../shared/api";
import { Solution } from "../models/Solution";
import { HsUser } from "../models/HsUser";
import { config } from "../config/index";

const baseUrl = config.getBaseUrl();

export const solutionsService = {
  async getAllSolutions() {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/solutions/`;
    const response = await publicGet(url);
    const solutions = response.data.solutions.map(item => new Solution(item));
    return solutions;
  },
  async getUserSolutions(token) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/solutions/user/`;
    const response = await get(url, { "x-hubspot": token });
    const userSolutions = response.data.solutions.map(
      item => new Solution(item)
    );
    return userSolutions;
  },
  async createUserSolution(id, token, preinstallCode) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/solutions/`;
    const data = {
      postData: { solution_id: id, preinstall_config_code: preinstallCode }
    };
    try {
      const response = await post(url, data, { "x-hubspot": token });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createOnboardingLink(id, token) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/solutions/${id}/user/`;
    const response = await get(url, { "x-hubspot": token });
    return response.data.link;
  },
  async getTokenFromPreinstallCode(preinstallCode) {
    const url = `${baseUrl}/api/v1/auth/proxy/token/preinstall-configuration/${preinstallCode}`;
    try {
      const response = await publicPost(url);
      return new HsUser(response.data);
    } catch (error) {
      throw error;
    }
  }
};
