<template>
  <div class="install-app">
    <div class="install-app__header">
      <div class="install-app__header--lynton-logo-wrapper">
        <img
          src="../assets/images/syncsmart-logo.svg"
          height="42"
          width="177"
          alt
        />
      </div>
      <div
        class="install-app__header--integration-logo-wrapper"
        v-if="showInstallationHeader"
      >
        <img :src="integrationImages.hubspot" height="40" width="122" alt />
        <img
          src="../assets/images/plus.svg"
          id="plus-img"
          height="14"
          width="14"
          alt
        />
        <img :src="integrationImages.solution" height="40" alt />
      </div>
      <form-steps
        v-if="
          activeStep !== 5 &&
            showInstallationHeader &&
            !preinstallConfigCodePresent
        "
        class="install-app__header--steps"
        :number-of-steps="4"
        :active-step="activeStep"
      />
      <h2
        class="install-app__header--title"
        v-if="activeStep !== 5 && showInstallationHeader && !removeTitle"
      >
        {{ title }}
      </h2>
    </div>
    <div class="install-app__steps-wrapper" :class="$route.name">
      <router-view
        @company-and-user-data-step-finished="
          companyAndUserInfoStepFinishedHandler
        "
        @billing-plans-step-finished="billingPlansStepFinishedHandler"
        @billing-info-step-finished="billingInfoStepFinishedHandler"
        @fetch-install-url="getInstallUrl"
        @save-billing-additional-data="saveBillingAdditionalDataHandler"
        @trigger-workflows="triggerWorkflows"
        :install-url="iframeUrl"
        :tray-config-ready="trayConfigReady"
        :company-and-user-data="{
          user: { ...stepsData.userInfo },
          company: { ...stepsData.companyInfo }
        }"
        :billing-plan="stepsData.billingPlan"
        :billing-step-data="billingStepData"
        :logger-link-data="loggerLinkData"
        :solution-id="solutionId"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { installDataStorage } from "@/utility/marketplaceSessionStorage";
import hubspotLogo from "../assets/images/hubspot-logo.svg";
import FormSteps from "../components/ui/FormSteps.component";
export default {
  components: {
    FormSteps
  },
  data() {
    return {
      integrationImages: {
        hubspot: hubspotLogo,
        solution: ""
      },
      iframeUrl: "",
      trayConfigReady: false,
      stepsData: {
        companyInfo: {},
        userInfo: {},
        billingPlan: {}
      },
      billingStepData: {},
      setupFinished: false
    };
  },
  computed: {
    solutionId() {
      return this.$route.params.id;
    },
    activeStep() {
      return parseInt(this.$route.name.split("-")[1]);
    },
    removeTitle() {
      const { removeTitle } = installDataStorage.getItem(
        this.solutionId,
        "installOptions"
      );
      return removeTitle;
    },
    title() {
      const {
        userRegistrationTitle,
        choosePackageTitle,
        billingInformationTitle,
        trayConfigTitle
      } = installDataStorage.getItem(this.solutionId, "installOptions");

      switch (this.activeStep) {
        case 1:
          return userRegistrationTitle || "Getting to Know You";
        case 2:
          return choosePackageTitle || "Choosing Your Package";
        case 3:
          return billingInformationTitle || "Adding Your Account Information";
        case 4:
          return trayConfigTitle || "Setting Up Your Integration";
        default:
          return "Setting Up Your Integration";
      }
    },
    loggerLinkData() {
      const portalId =
        installDataStorage.getItem(this.solutionId, "portalId") || null;
      const solutionInstanceId =
        installDataStorage.getItem(this.solutionId, "solutionInstanceId") ||
        null;
      return { portalId, solutionInstanceId };
    },
    showInstallationHeader() {
      return this.$route.name !== "install-app-error-screen";
    },
    preinstallConfigCodePresent() {
      return installDataStorage.getItem(
        this.solutionId,
        "preinstallConfigCode"
      );
    },
    skipAllSteps() {
      const {
        postSetupRedirectUrl,
        skipTrayConfigWizard,
        skipBillingStep,
        skipUserRegistration
      } = installDataStorage.getItem(this.solutionId, "installOptions");
      return (
        postSetupRedirectUrl &&
        skipTrayConfigWizard &&
        skipBillingStep &&
        skipUserRegistration
      );
    },
    trayCancelBtnRedirectUrl() {
      const { trayConfigCancelBtnUrl } = installDataStorage.getItem(
        this.solutionId,
        "installOptions"
      );
      if (trayConfigCancelBtnUrl) return trayConfigCancelBtnUrl;
      const { cancelBtnRedirectUrl } = installDataStorage.getItem(
        this.solutionId,
        "solutionData"
      );
      return cancelBtnRedirectUrl;
    }
  },
  created() {
    if (this.$route.name !== "auto-install-app") {
      this.$router.replace({ name: "auto-install-app" });
    }
    window.addEventListener("message", this.handleIframeEvents);
    const { id } = this.$route.params;
    const { code, preinstall_config_code } = this.$route.query;
    const token = sessionStorage.getItem(id)
      ? installDataStorage.getItem(id, "token")
      : "";
    if (code) {
      this.initInstallAppWithHubspotAuth(code, id);
      return;
    }
    if (preinstall_config_code) {
      installDataStorage.init(id);
      this.initWithPreinstallCode(preinstall_config_code, id);
      return;
    }
    if (token) {
      if (installDataStorage.isValid(id)) {
        this.initInstallApp(id);
      } else {
        installDataStorage.init(id);
        this.$router.push({ name: "install-app-error-screen" });
      }
    } else if (id) {
      installDataStorage.init(id);
      this.initHubspotAuth(id);
    } else {
      this.iframeUrl = this.$route.params.installUrl;
      this.showFirstStep();
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleIframeEvents);
  },
  methods: {
    async initWithPreinstallCode(preinstallCode, id) {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      installDataStorage.setItem(id, "preinstallConfigCode", preinstallCode);
      try {
        const hsUser = await this.$store.dispatch(
          "marketplace/getTokenFromPreinstallCode",
          preinstallCode
        );
        if (hsUser) {
          const { hsUserId, email, portalId, token } = hsUser;
          this.stepsData.userInfo.email = email;
          installDataStorage.setItem(id, "email", email);
          installDataStorage.setItem(id, "hsUserId", hsUserId);
          installDataStorage.setItem(id, "portalId", portalId);
          installDataStorage.setItem(id, "token", token);
          const solutionData = await this.getSolution(id);
          if (solutionData.id) {
            installDataStorage.setItem(id, "solutionData", solutionData);
            await this.createUserSolution(token, preinstallCode, id);
            await this.getBillingPlans(id);
            this.showFirstStep();
            if (!this.skipAllSteps) {
              this.$store.dispatch("app/setAppSpinnerVisible", false);
            }
          }
        }
      } catch (error) {
        if (error.status === 404) {
          this.initHubspotAuth(id);
        } else {
          this.$router.push({ name: "install-app-error-screen" });
          this.$store.dispatch("app/setAppSpinnerVisible", false);
        }
      }
    },
    async getSolution(id) {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      try {
        const solutionData = await this.$store.dispatch(
          "marketplace/fetchSolutionData",
          id
        );
        if (solutionData.id) {
          return solutionData;
        }
      } catch {
        this.$router.push({ name: "install-app-error-screen" });
        this.$store.dispatch("app/setAppSpinnerVisible", false);
      }
    },
    async initInstallApp(id) {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      this.stepsData.userInfo.email = installDataStorage.getItem(id, "email");
      try {
        await this.getBillingPlans(id);
        this.showFirstStep();
        if (!this.skipAllSteps) {
          this.$store.dispatch("app/setAppSpinnerVisible", false);
        }
      } catch {
        this.$router.push({ name: "install-app-error-screen" });
        this.$store.dispatch("app/setAppSpinnerVisible", false);
      }
    },
    async initHubspotAuth(id) {
      const redirectUrl = window.location.href.split("?")[0];
      sessionStorage.setItem("redirectUrl", redirectUrl);
      const solutionData = await this.getSolution(id);
      installDataStorage.setItem(id, "solutionData", solutionData);
      const { hsAuthUrl } = solutionData;
      window.location.href = `${hsAuthUrl}&redirect_uri=${redirectUrl}`;
    },
    async initInstallAppWithHubspotAuth(code, id) {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      const solutionData = installDataStorage.getItem(id, "solutionData");
      const preinstallCode = installDataStorage.getItem(
        id,
        "preinstallConfigCode"
      );
      try {
        const hsUser = await this.getToken(
          code,
          redirectUrl,
          solutionData.hsAppId
        );
        const { hsUserId, email, portalId, token } = hsUser;
        installDataStorage.setItem(id, "email", email);
        installDataStorage.setItem(id, "hsUserId", hsUserId);
        installDataStorage.setItem(id, "portalId", portalId);
        installDataStorage.setItem(id, "token", token);
        await this.createUserSolution(token, preinstallCode, id);
        await this.getBillingPlans(id);
        this.showFirstStep();
        if (!this.skipAllSteps) {
          this.$store.dispatch("app/setAppSpinnerVisible", false);
        }
      } catch (error) {
        this.$router.push({ name: "install-app-error-screen" });
        this.$store.dispatch("app/setAppSpinnerVisible", false);
      }
    },
    async getToken(code, url, hsAppId) {
      try {
        const response = await this.$store.dispatch("hubspot/fetchHsUser", {
          code,
          hsAppId,
          url
        });
        this.stepsData.userInfo.email = response.email;
        return response;
      } catch (error) {
        const url = window.location.href.split("?")[0];
        window.location.href = url;
      }
    },
    async createUserSolution(token, preinstallCode, id) {
      const response = await this.$store.dispatch(
        "marketplace/createUserSolution",
        {
          token,
          preinstallCode,
          id
        }
      );
      installDataStorage.setItem(
        id,
        "solutionInstanceId",
        response.solutionInstanceId
      );
      installDataStorage.setItem(id, "installOptions", response.installOptions);
    },
    async getBillingPlans(id) {
      await this.$store.dispatch("billing/fetchSolutionPlansData", id);
    },
    async getInstallUrl() {
      this.iframeUrl = "";
      try {
        const token = installDataStorage.getItem(this.solutionId, "token");
        const solutionInstanceId = installDataStorage.getItem(
          this.solutionId,
          "solutionInstanceId"
        );
        const response = await this.$store.dispatch(
          "marketplace/fetchInstallUrl",
          { token, solutionInstanceId }
        );
        if (response) {
          this.iframeUrl = response.configLink;
        }
      } catch {
        this.$router.push({ name: "install-app-error-screen" });
      }
    },
    showFirstStep() {
      const portalId = installDataStorage.getItem(this.solutionId, "portalId");
      const email = installDataStorage.getItem(this.solutionId, "email");
      const { img, title, hsAppId } = installDataStorage.getItem(
        this.solutionId,
        "solutionData"
      );
      document.title = `${title} Setup`;
      this.integrationImages.solution = img;
      this.$store.dispatch("hubspot/postHsForm", {
        email,
        portalId,
        hubspotAppId: hsAppId
      });
      this.$router.replace({
        name: "step-1"
      });
    },
    async handleIframeEvents(e) {
      if (e.data.type === "tray.configPopup.error") {
        alert(`Error: ${e.data.err}`);
      }
      if (e.data.type === "tray.configPopup.finish") {
        this.triggerWorkflows();
      }
      if (e.data.type === "tray.configPopup.cancel") {
        window.location.href = this.trayCancelBtnRedirectUrl;
      }
      if (e.data.type === "tray.configPopup.ready") {
        this.trayConfigReady = true;
      }
    },
    async triggerWorkflows() {
      const token = installDataStorage.getItem(this.solutionId, "token");
      const solutionInstanceId = installDataStorage.getItem(
        this.solutionId,
        "solutionInstanceId"
      );
      const preinstallConfigCode =
        installDataStorage.getItem(this.solutionId, "preinstallConfigCode") ||
        null;
      await this.$store.dispatch("billing/triggerWorkflows", {
        token,
        solutionInstanceId,
        preinstallConfigCode
      });
      this.showSuccessStep();
    },
    companyAndUserInfoStepFinishedHandler({ user, company }) {
      const { hsAppId } = installDataStorage.getItem(
        this.solutionId,
        "solutionData"
      );
      this.stepsData.companyInfo = { ...company };
      this.stepsData.userInfo = { ...user };
      this.billingStepData.name = `${user.firstName} ${user.lastName}`;
      this.$router.push({ name: "step-2" });
      this.$store.dispatch("hubspot/postHsForm", {
        company: company.companyName,
        website: company.companyWebsiteUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        email: user.email,
        hubspotAppId: hsAppId
      });
    },
    async billingPlansStepFinishedHandler(planData) {
      const { email } = this.stepsData.userInfo;
      const { hsAppId } = installDataStorage.getItem(
        this.solutionId,
        "solutionData"
      );
      this.stepsData.billingPlan = planData;
      if (planData.isQuote) {
        await this.billingInfoStepFinishedHandler({
          source: null,
          additionalData: null
        });
        return;
      }
      this.$router.push({ name: "step-3" });
      this.$store.dispatch("hubspot/postHsForm", {
        hubspotAppId: hsAppId,
        billingPlan: planData.id,
        email
      });
    },
    async billingInfoStepFinishedHandler({
      source,
      additionalData,
      stopLoading
    }) {
      const { hsAppId } = await installDataStorage.getItem(
        this.solutionId,
        "solutionData"
      );
      const solutionInstanceId = await installDataStorage.getItem(
        this.solutionId,
        "solutionInstanceId"
      );
      const token = await installDataStorage.getItem(this.solutionId, "token");
      const portalId = await installDataStorage.getItem(
        this.solutionId,
        "portalId"
      );
      const { stepsData } = this;
      const { email } = stepsData.userInfo;
      const data = {
        token,
        portalId,
        source,
        billingPlan: stepsData.billingPlan.id,
        solutionInstanceId,
        metadata: { ...stepsData.companyInfo, ...stepsData.userInfo }
      };
      try {
        await this.$store.dispatch("billing/submitBillingInfo", data);
        // stopLoading();
      } catch (e) {
        this.$router.push({ name: "install-app-error-screen" });
        // this.$message.error(e.response);
        stopLoading();
        return;
      }
      if (additionalData) {
        const {
          addressLine1,
          addressCity,
          addressCountry,
          addressState,
          addressZip,
          phone
        } = additionalData;
        const formData = {
          address: addressLine1,
          city: addressCity,
          country: addressCountry,
          zip: addressZip,
          phone
        };
        if (addressState) formData.state = addressState;
        this.$store.dispatch("hubspot/postHsForm", {
          hubspotAppId: hsAppId,
          email,
          ...formData
        });
      }
      this.$router.push({ name: "step-4" });
    },
    saveBillingAdditionalDataHandler(data) {
      this.billingStepData = { ...data };
    },
    showSuccessStep() {
      this.setupFinished = true;
      const { postSetupRedirectUrl } = installDataStorage.getItem(
        this.solutionId,
        "installOptions"
      );
      if (postSetupRedirectUrl) {
        window.location.href = postSetupRedirectUrl;
        return;
      }
      this.$router.push({ name: "step-5" });
    }
  }
};
</script>

<style lang="scss" scoped>
.install-app {
  text-align: center;
  padding-bottom: 100px;
  &:before {
    content: "";
    width: 100%;
    height: 605px;
    background: transparent linear-gradient(289deg, #070fec 0%, #4c60f8 100%) 0%
      0% no-repeat padding-box;
    display: block;
    position: absolute;
    z-index: -1;
  }
  &__header {
    &--lynton-logo-wrapper {
      height: 70px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--integration-logo-wrapper {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      #plus-img {
        margin: 0 14px;
      }
    }
    &--steps {
      margin: 24px auto 0;
    }
    &--title {
      font-size: 24px;
      line-height: 31px;
      font-weight: 700;
      color: #ffffff;
      margin-top: 24px;
    }
    &--text {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  &__steps-wrapper {
    padding: 0 20px;
    &--step {
      margin-top: 44px;
      display: inline-block;
      width: 600px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
      overflow: hidden;
      padding: 24px;
    }
    &.step-4 {
      .install-app__steps-wrapper--step {
        padding: 0;
      }
    }
    &--plans-step {
      margin: 26px auto 0;
      width: 1024px;
    }
  }
}

@media (max-width: 1080px) {
  .install-app {
    &__steps-wrapper {
      &--plans-step {
        width: 100%;
      }
    }
  }
}

@media (max-width: 630px) {
  .install-app {
    padding-bottom: 50px;
    &__header {
      &--lynton-logo-wrapper {
        height: 60px;
        img {
          width: 147;
          height: 35px;
        }
      }
      &--integration-logo-wrapper {
        img {
          &:first-of-type {
            width: 107px;
            height: 35px;
          }
          &:last-of-type {
            width: 83px;
            height: 35px;
          }
        }
      }
    }
    &__steps-wrapper {
      &--step {
        width: 100%;
      }
    }
  }
}
</style>
