<template>
  <div class="top-bar">
    <container>
      <div class="top-bar__logo-wrapper"></div>
      <nav class="top-bar__nav">
        <router-link to="/">Marketplace</router-link>
        <router-link to="/my-apps">Installed Apps</router-link>
      </nav>
      <button
        type="button"
        class="top-bar__change-portal-btn"
        @click="portalBtnClickHandler"
      >
        {{ portalId }}
      </button>
    </container>
  </div>
</template>

<script>
import Container from "./../layout/Container.component.vue";
export default {
  components: {
    Container
  },
  props: {
    portalId: {
      type: Number
    }
  },
  methods: {
    portalBtnClickHandler() {
      this.$emit("change-portal");
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  height: 60px;
  background: #33475b;
  flex-shrink: 0;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo-wrapper {
    width: 137px;
    height: 35px;
    background-image: url("../../assets/images/lw-logo-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  &__nav {
    a {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      text-decoration: none;
      margin-right: 40px;
      &:last-of-type {
        margin-right: 0;
      }
      &.router-link-exact-active {
        text-decoration: underline;
      }
    }
  }
  &__change-portal-btn {
    height: 33px;
    width: 115px;
    border-radius: 3px;
    background-color: #ffffff;
    border: none;
    color: #33475b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
