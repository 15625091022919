export class Solution {
  constructor(solution) {
    this.id = solution.id;
    this.title = solution.title || solution.name;
    this.description = solution.description;
    this.img = solution.customFields.marketplace_wizard_image_url;
    this.hsAuthUrl = solution.customFields.marketplace_hs_oauth_url;
    this.hsAppId = solution.customFields.marketplace_hs_app_id;
    this.billing = solution.customFields.marketplace_billing_plans;
    this.cancelBtnRedirectUrl =
      solution.customFields.cancel_btn_redirect_url || "";
  }
}
