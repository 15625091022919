import { publicPost } from "../shared/api";
import { config } from "../config/index";

const hubspotFormsApiUrl =
  "https://api.hsforms.com/submissions/v3/integration/submit/74005";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://lw-marketplace-stag-ui.herokuapp.com"
    : "http://localhost";

let hsTrackingCookie = document.cookie.match(
  new RegExp("(^| )hubspotutk=([^;]+)")
);
if (hsTrackingCookie) hsTrackingCookie = hsTrackingCookie[2];

export const hubspotFormsService = {
  hubspotFormsEndpoint() {
    return [hubspotFormsApiUrl, ...arguments].join("/") + "/";
  },
  mapData(data, options) {
    options = options || {};
    const ignoreUnmapped = options.ignoreUnmapped || false;
    const fieldMapping = options.fieldMapping || {};
    const skipValidation = options.skipValidation || false;

    const fields = [];

    Object.keys(data).forEach(key => {
      let mappedKey = fieldMapping[key];

      let field = {
        name: mappedKey || key,
        value: data[key] || ""
      };

      if (!ignoreUnmapped || mappedKey) fields.push(field);
    });

    let context = Object.assign(
      {
        pageUri: baseUrl,
        ipAddress: null,
        pageName: "Solution integration",
        hutk: hsTrackingCookie
      },
      options.context || {}
    );

    return {
      fields: fields,
      context: context,
      skipValidation: skipValidation
    };
  },
  postData(form_id, data, options) {
    const formData = this.mapData(data, options);

    if (config.isProduction())
      return publicPost(this.hubspotFormsEndpoint(form_id), formData);
  },
  postForm(data) {
    return this.postData("c90831f0-da51-4f2a-9867-e44b4096e01a", data, {
      fieldMapping: {
        email: "email",
        message: "message",
        portalId: "crm_hub_id",
        hubspotAppId: "hubspot_app",
        company: "company",
        website: "website",
        firstName: "firstname",
        lastName: "lastname",
        role: "jobrole",
        billingPlan: "app_billing_plan",
        address: "address",
        city: "city",
        country: "country",
        state: "state",
        zip: "zip",
        phone: "phone"
      },
      ignoreUnmapped: true,
      skipValidation: true
    });
  }
};
