import { render, staticRenderFns } from "./TrayWizard.vue?vue&type=template&id=a66e6a9c&scoped=true"
import script from "./TrayWizard.vue?vue&type=script&lang=js"
export * from "./TrayWizard.vue?vue&type=script&lang=js"
import style0 from "./TrayWizard.vue?vue&type=style&index=0&id=a66e6a9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a66e6a9c",
  null
  
)

export default component.exports