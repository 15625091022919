import { solutionsService } from "./../../../services/solutionsService";

const fetchData = async context => {
  context.commit("SET_LOADING", true);
  const { token } = JSON.parse(sessionStorage.getItem("hsUser"));
  const data = await solutionsService.getUserSolutions(token);
  context.commit("SET_DATA", data);
  context.commit("SET_LOADING", false);
};

export default {
  fetchData
};
