<template>
  <div class="install-app__steps-wrapper--step">
    <success-step
      :billing-plan="billingPlan"
      :logger-link-data="loggerLinkData"
    />
  </div>
</template>

<script>
import SuccessStep from "../success-screen/SuccessScreen.component.vue";
export default {
  components: {
    SuccessStep
  },
  props: {
    billingPlan: {
      type: Object,
      required: true,
      default() {
        return {
          id: ""
        };
      }
    },
    loggerLinkData: {
      type: Object,
      required: true,
      default() {
        return {
          solutionInstanceId: "",
          portalId: 0
        };
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "solution-instance-logs") {
      next();
    } else {
      next({ name: "step-5" });
    }
  }
};
</script>
