import Vue from "vue";
import VueRouter from "vue-router";
// import Marketplace from "../views/Marketplace.vue";
// import Solution from "../views/Solution.vue";
import Logger from "../views/Logger.vue";
import InstallApp from "../views/InstallApp.vue";
import InstallAppStep1 from "../components/install-app/steps/UserAndCompanyInfoStep.component.vue";
import InstallAppStep2 from "../components/install-app/steps/BillingPlansStep.component.vue";
import InstallAppStep3 from "../components/install-app/steps/BillingInfoStep.component.vue";
import InstallAppStep4 from "../components/install-app/steps/TrayConfigStep.component.vue";
import InstallAppStep5 from "../components/install-app/steps/SuccessStep.component.vue";
import InstallAppErrorScreen from "../components/install-app/steps/ErrorStep.component.vue";
import SolutionInstanceLogs from "../views/SolutionInstanceLogs.vue";
import TrayWizard from "../views/TrayWizard.vue";
// import MyApps from "../views/MyApps.vue";

// import { config } from "../config/index";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "marketplace",
  //   component: Marketplace
  // },
  // {
  //   path: "/marketplace/:id",
  //   name: "solution",
  //   component: Solution
  // },
  // {
  //   path: "/install-app/",
  //   name: "install-app",
  //   component: InstallApp
  // },
  {
    path: "/logger",
    name: "logger",
    component: Logger,
    meta: {
      hideNav: true,
      title: "Logger"
    }
  },
  {
    path: "/auto-install-app/:id",
    name: "auto-install-app",
    component: InstallApp,
    meta: {
      hideNav: true
      // title: "Classy/HubSpot Integration Set Up"
    },
    children: [
      {
        path: "1",
        name: "step-1",
        component: InstallAppStep1,
        meta: {
          hideNav: true
        }
      },
      {
        path: "2",
        name: "step-2",
        component: InstallAppStep2,
        meta: {
          hideNav: true
        }
      },
      {
        path: "3",
        name: "step-3",
        component: InstallAppStep3,
        meta: {
          hideNav: true
        }
      },
      {
        path: "4",
        name: "step-4",
        component: InstallAppStep4,
        meta: {
          hideNav: true
        }
      },
      {
        path: "5",
        name: "step-5",
        component: InstallAppStep5,
        meta: {
          hideNav: true
        }
      },
      {
        path: "error",
        name: "install-app-error-screen",
        component: InstallAppErrorScreen,
        meta: {
          hideNav: true
        }
      }
    ]
  },
  {
    path: "/:portalId/installed-apps/:solutionInstanceId/logs",
    name: "solution-instance-logs",
    component: SolutionInstanceLogs,
    meta: {
      hideNav: true
    }
  },
  {
    path: "/tray-wizard",
    name: "tray-wizard",
    component: TrayWizard,
    meta: {
      hideNav: true
    }
  }
  // {
  //   path: "/my-apps",
  //   name: "my-apps",
  //   component: MyApps
  // }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
//   // This goes through the matched routes from last to first, finding the closest route with a title.
//   // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
//   const nearestWithTitle = to.matched
//     .slice()
//     .reverse()
//     .find(r => r.meta && r.meta.title);

//   // If a route with a title was found, set the document (page) title to that value.
//   if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

//   next();
// });

export default router;
