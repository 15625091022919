const loading = state => state.loading;
const data = state => state.data;
const solutionData = state => state.solutionPlansData;
const billingResponseData = state => state.billingResponseData;

export default {
  loading,
  data,
  solutionData,
  billingResponseData
};
