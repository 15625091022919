<template>
  <div class="logger">
    <div class="logger__filter">
      <div class="logger__filter--status">
        <el-select v-model="filter.status" placeholder="Select Status">
          <div slot="prefix">Activity type:</div>
          <el-option
            v-for="item in filterStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="logger__filter--record" v-if="!hideRecordTypeDropdown">
        <el-select v-model="filter.record" placeholder="Select record type">
          <div slot="prefix">Record type:</div>
          <el-option
            v-for="item in filterRecordOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="logger__filter--operation">
        <el-select v-model="filter.operation" placeholder="Select operation">
          <div slot="prefix">Operation:</div>
          <el-option
            v-for="item in filterOperationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="logger__filter--date">
        <el-date-picker
          clearable
          v-model="filter.date"
          value-format="yyyy-MM-dd"
          format="M/d/yyyy"
          type="daterange"
          range-separator="to"
          start-placeholder="Start date"
          end-placeholder="End date"
        ></el-date-picker>
      </div>
      <div class="logger__filter--input">
        <el-input
          placeholder="Search"
          v-model="filter.input.text"
          class="input-with-select"
        >
          <el-select
            v-model="filter.input.type"
            slot="append"
            placeholder="Search by"
          >
            <el-option label="by Name" value="entity__name"></el-option>
            <el-option label="by Email" value="entity__email"></el-option>
            <el-option label="by Message" value="message"></el-option>
          </el-select>
        </el-input>
      </div>
      <div class="logger__filter--button">
        <el-button type="primary" @click="filterLogs()">
          <i class="el-icon-search"></i> Search
        </el-button>
      </div>
    </div>
    <div class="logger__table" v-if="loggerData">
      <el-table :data="loggerData.results" stripe>
        <el-table-column prop="status" label="Sync Status" width="150">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status === 'SUCCESS'
                  ? 'success'
                  : scope.row.status === 'ERROR'
                  ? 'danger'
                  : scope.row.status === 'WARNING'
                  ? 'warning'
                  : 'info'
              "
              disable-transitions
              >{{ scope.row.status }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="time" label="Time" width="180">
          <template slot-scope="scope">{{ scope.row.time }}</template>
        </el-table-column>
        <el-table-column prop="entity" label="Name">
          <template slot-scope="scope">
            <span v-if="scope.row.entity.hubspot_id">
              <a :href="scope.row.entity.url">{{ scope.row.entity.name }}</a>
            </span>
            <span v-else>{{ scope.row.entity.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="entity.email" label="Email"></el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <p>
              <strong>Record type:</strong>
              &nbsp;
              {{ scope.row.entity.type }}
            </p>
            <p>
              <strong>Operation:</strong>
              &nbsp;
              {{ scope.row.operation }}
            </p>
            <p>
              <strong>Full message:</strong>
              &nbsp;
              {{ scope.row.message }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="messagePreview"
          label="Message Preview"
          width="350"
        ></el-table-column>
      </el-table>
    </div>
    <div class="logger__controls" v-if="loggerData">
      <el-button-group>
        <el-button
          type="primary"
          :disabled="previousButtonDisabled"
          @click="previousPageClickHandler()"
        >
          <i class="el-icon-arrow-left"></i> Previous Page
        </el-button>
        <el-button
          type="primary"
          :disabled="nextButtonDisabled"
          @click="nextPageClickHandler()"
        >
          Next Page
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import { loggerService } from "../services/loggerService";
export default {
  props: {
    portalId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loggerData: null,
      filterStatusOptions: [
        { value: null, label: "All" },
        { value: "SUCCESS", label: "Success" },
        { value: "ERROR", label: "Error" },
        { value: "WARNING", label: "Warning" }
      ],
      filterRecordOptions: [
        { value: null, label: "All" },
        { value: "COMPANY", label: "Company" },
        { value: "CONTACT", label: "Contact" },
        { value: "DEAL", label: "Deal" }
      ],
      filterOperationOptions: [
        { value: null, label: "All" },
        { value: "Create", label: "Create" },
        { value: "Update", label: "Update/Insert" },
        { value: "Lookup", label: "Lookup" }
      ],
      filter: {
        status: "ERROR",
        record: null,
        operation: null,
        input: {
          text: "",
          type: "entity__name"
        },
        date: null
      }
    };
  },
  computed: {
    nextButtonDisabled() {
      return this.loggerData.next === null;
    },
    previousButtonDisabled() {
      return this.loggerData.previous === null;
    },
    hideRecordTypeDropdown() {
      return (
        this.$route.query.hasOwnProperty("entity__type") &&
        this.$route.query.hasOwnProperty("entity__hubspot_id")
      );
    }
  },
  created() {
    if (this.portalId) this.filter.status = null; // if portal id is provided reset filter from ERROR to ALL
    this.setDateFilterToDaysAgo(10);
    this.filterLogs();
  },
  methods: {
    setDateFilterToDaysAgo(days) {
      let today = new Date();
      today = this.formatDate(today);
      let daysAgo = new Date();
      daysAgo.setDate(daysAgo.getDate() - days);
      daysAgo = this.formatDate(daysAgo);
      this.filter.date = [daysAgo, today];
    },
    formatDate(date) {
      const dateArray = date.toLocaleDateString("en-US").split("/");
      const year = dateArray.splice(2, 1);
      return [...year, ...dateArray].join("-");
    },
    async previousPageClickHandler() {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      this.loggerData = await loggerService.goToPage(this.loggerData.previous);
      this.$store.dispatch("app/setAppSpinnerVisible", false);
    },
    async nextPageClickHandler() {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      this.loggerData = await loggerService.goToPage(this.loggerData.next);
      this.$store.dispatch("app/setAppSpinnerVisible", false);
    },
    async filterLogs() {
      this.$store.dispatch("app/setAppSpinnerVisible", true);
      try {
        if (this.portalId) {
          this.loggerData = await loggerService.applyFilter(
            { organization__hubspot_portal_id: this.portalId },
            this.filter
          );
        } else {
          this.loggerData = await loggerService.applyFilter(
            this.$route.query,
            this.filter
          );
        }
      } catch (error) {
        const { title, message } = error;
        this.$notify.error({
          title,
          message,
          duration: 0
        });
      } finally {
        this.$store.dispatch("app/setAppSpinnerVisible", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logger {
  &__filter {
    display: flex;
    flex-wrap: wrap;
    &--status {
      width: 220px;
      margin: 20px 20px 0 0;
      .el-select::v-deep {
        .el-input__inner {
          padding-left: 100px;
        }
      }
    }
    &--record {
      width: 200px;
      margin: 20px 20px 0 0;
      .el-select::v-deep {
        .el-input__inner {
          padding-left: 96px;
        }
      }
    }
    &--operation {
      width: 210px;
      margin: 20px 20px 0 0;
      .el-select::v-deep {
        .el-input__inner {
          padding-left: 84px;
        }
      }
    }
    &--status,
    &--record,
    &--operation {
      .el-input__prefix::v-deep {
        padding-left: 2px;
        color: #606266;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &--input {
      margin: 20px 20px 0 0;
      .el-select::v-deep {
        .el-input {
          width: 110px;
        }
      }
    }
    &--date {
      margin: 20px 20px 0 0;
      .el-date-editor::v-deep {
        width: 410px;
      }
    }
    &--button {
      margin-top: 20px;
    }
  }
  &__table {
    &--header {
      display: flex;
      border-bottom: 1px solid #607285;
      div {
        flex: 1;
        padding: 14px 10px;
        text-align: left;
        &:nth-of-type(2) {
          flex: 2;
        }
        &:nth-of-type(3),
        &:nth-of-type(5) {
          flex: 3;
        }
      }
    }
  }
  &__controls {
    margin: 20px 0;
  }
}
</style>
