import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import marketplaceModule from "./modules/marketplace/index";
import hubspotModule from "./modules/hubspot/index";
import myAppsModule from "./modules/my-apps/index";
import appModule from "./modules/app/index";
import billingModule from "./modules/billing/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    marketplace: marketplaceModule,
    hubspot: hubspotModule,
    myApps: myAppsModule,
    app: appModule,
    billing: billingModule
  },
  actions
});
