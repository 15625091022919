import { get } from "../shared/api";
import { Log } from "../models/Log";

export const loggerService = {
  async goToPage(url) {
    const response = await get(url, {
      "X-IntegrationAdmin-Signature": "22059166DC3F4F518D8C3B13739AF4D5"
    });
    return this.formatResponseData(response);
  },
  async applyFilter(queryData, filter) {
    let url = "https://lynton-logger.herokuapp.com/api/log/";
    const queryParams = [];
    for (let item in queryData) {
      queryParams.push(`${item}=${queryData[item]}`);
    }
    url += `?${queryParams.join("&")}`;
    if (filter.status) {
      url += `&log_level=${filter.status}`;
    }
    if (filter.record) {
      url += `&entity__type=${filter.record}`;
    }
    if (filter.operation) {
      url += `&hubspot_solution__operation__icontains=${filter.operation}`;
    }
    if (filter.input.text) {
      url += `&${filter.input.type}__icontains=${filter.input.text}`;
    }
    if (filter.date) {
      const start = this.formatDate(filter.date[0]);
      const end = this.formatDate(filter.date[1], 1);
      url += `&time_stamp_0=${start}&time_stamp_1=${end}`;
    }
    try {
      const response = await get(url, {
        "X-IntegrationAdmin-Signature": "22059166DC3F4F518D8C3B13739AF4D5"
      });
      return this.formatResponseData(response);
    } catch {
      throw {
        title: "Something went wrong",
        message: "Try again or contact support at support@lyntonweb.com."
      };
    }
  },
  formatResponseData(responseData) {
    let { next, previous, results } = responseData;
    if (next) next = next.replace("http://", "https://");
    if (previous) previous = previous.replace("http://", "https://");
    const informationalLogsRemoved = results.filter(
      item => item.log_level !== "INFORMATIONAL"
    );
    const data = {
      next,
      previous,
      results: informationalLogsRemoved.map(item => new Log(item))
    };
    return data;
  },
  formatDate(dateValue, plusDaysValue) {
    let dateTime = new Date(`${dateValue} 00:00:00`);
    const utcOffset = new Date().getTimezoneOffset() / 60;
    if (plusDaysValue) dateTime.setDate(dateTime.getDate() + plusDaysValue); // add a day
    dateTime.setHours(dateTime.getHours() + utcOffset); // add UTC offset
    const dateTimeArray = dateTime
      .toLocaleString("en-US", { hour12: false })
      .split(",");
    const date = dateTimeArray[0];
    const time = dateTimeArray[1].trim();
    const dateArray = date.split("/");
    const year = dateArray.splice(2, 1);
    dateTime = encodeURIComponent(
      `${[...year, ...dateArray].join("-")} ${time}`
    );
    return dateTime;
  }
};
