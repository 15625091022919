<template>
  <div class="tray-config">
    <div v-if="!trayDataSent" class="tray-config__step">
      <div class="tray-config__step-content">
        <h4>{{ currentStepData.title }}</h4>
        <p>{{ currentStepData.description }}</p>
        <tray-input
          v-for="item in currentStepData.data"
          :input-data="item"
          :key="item.id"
          @input-data-changed="inputDataChanged"
        />
      </div>
      <div class="tray-config__step-controls">
        <div class="tray-config__step-controls-info">{{ stepsInfo }}</div>
        <div class="tray-config__step-controls-buttons">
          <el-button :disabled="disablePreviousBtn" @click="previousStep"
            >Previous</el-button
          >
          <el-button @click="nextStep">{{ nextBtnText }}</el-button>
        </div>
      </div>
    </div>
    <div v-else class="tray-config__summary">
      <div v-for="(step, index) in steps" :key="index">
        <div>
          <strong>Step {{ index + 1 }}:</strong>
        </div>
        <div v-for="item in step.data" :key="item.id">
          <span>{{ item.propertyName }}: {{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrayDemoData from "../utility/trayDemoData";
import TrayInput from "../components/tray-wizard/TrayInput.component.vue";
export default {
  components: {
    TrayInput
  },
  data() {
    return {
      currentStep: 1,
      steps: [],
      trayDataSent: false
    };
  },
  computed: {
    currentStepData() {
      return this.steps.length ? this.steps[this.currentStep - 1] : {};
    },
    stepsInfo() {
      return `${this.currentStep}/${this.steps.length}`;
    },
    disablePreviousBtn() {
      return this.currentStep === 1;
    },
    nextBtnText() {
      return this.currentStep === this.steps.length ? "Finish" : "Next";
    }
  },
  mounted() {
    this.steps = TrayDemoData;
  },
  methods: {
    previousStep() {
      this.currentStep -= 1;
    },
    nextStep() {
      if (this.stepDataValid(this.currentStepData)) {
        this.currentStep === this.steps.length
          ? this.sendTrayData()
          : (this.currentStep += 1);
      } else {
        for (const item of this.steps[this.currentStep - 1].data) {
          if (item.required && !item.value) item.valid = false;
        }
      }
    },
    stepDataValid({ data }) {
      for (const item of data) {
        if (item.required && !item.value) return false;
      }
      return true;
    },
    inputDataChanged({ id, value }) {
      const index = this.steps[this.currentStep - 1].data.findIndex(
        item => item.id === id
      );
      this.steps[this.currentStep - 1].data[index].value = value;
      this.steps[this.currentStep - 1].data[index].valid = this.steps[
        this.currentStep - 1
      ].data[index].required
        ? this.steps[this.currentStep - 1].data[index].value.length > 0
        : true;
    },
    sendTrayData() {
      this.trayDataSent = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.tray-config {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(289deg, #070fec 0%, #4c60f8 100%) 0%
    0% no-repeat padding-box;
  &__step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    min-height: 400px;
    padding: 24px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    &-content {
      h4 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }
    &-controls {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__summary {
    width: 600px;
    min-height: 400px;
    padding: 24px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    text-align: left;
    > div {
      margin-top: 20px;
    }
  }
}
</style>
