<template>
  <div class="billing-plans-item">
    <h4 class="billing-plans-item__title">{{ planData.name }}</h4>
    <div
      class="billing-plans-item__lead"
      v-for="item in planData.metadata.lead"
      :key="item.value"
    >
      <div class="billing-plans-item__lead--value">{{ item.value }}</div>
      <div class="billing-plans-item__lead-text">
        # of {{ item.text.toLowerCase() }}
      </div>
    </div>
    <div class="billing-plans-item__price">
      <div
        class="billing-plans-item__price--value"
        v-if="planData.metadata.isQuote"
      >
        Custom
      </div>
      <div class="billing-plans-item__price--value" v-else>
        <small class="currency">$</small>
        {{ planData.amount }}
        <small class="interval"
          >/{{ `${planData.interval[0]}${planData.interval[1]}` }}</small
        >
      </div>
    </div>
    <el-button
      class="billing-plans-item__btn"
      :loading="loading"
      @click="planSelectedHandler"
      >{{ `${planData.metadata.submitButtonText}` }}</el-button
    >
    <div
      class="billing-plans-item__bold"
      v-for="item in planData.metadata.bold"
      :key="item.value"
    >
      {{ item.text }}
      <strong>{{ item.value }}</strong>
    </div>
    <div class="billing-plans-item__checks">
      <div
        class="billing-plans-item__checks--check"
        :class="{ checked: item.value }"
        v-for="item in planData.metadata.check"
        :key="item.text"
      >
        {{ item.text }}
      </div>
    </div>
    <div
      class="billing-plans-item__bottom"
      v-for="item in planData.metadata.bottom"
      :key="`${item.value} 1`"
    >
      {{ item.value }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    planData: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    planSelectedHandler() {
      this.loading = true;
      const { planData } = this;
      const data = {
        id: planData.id,
        isQuote: planData.metadata.isQuote,
        success: { ...planData.metadata.success }
      };
      this.$emit("plan-selected", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.billing-plans-item {
  width: 328px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  padding: 54px 27px 33px;
  &__title {
    color: #d72f67;
    font-size: 30px;
    font-weight: 900;
    line-height: 44px;
  }
  &__lead {
    margin-top: 16px;
    &--value {
      color: #4b5c6e;
      font-size: 36px;
      font-weight: 900;
      line-height: 49px;
    }
    &--text {
      color: #4b5c6e;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
    }
  }
  &__price {
    margin-top: 16px;
    &--value {
      color: #4b5c6e;
      font-size: 36px;
      height: 36px;
      font-weight: 900;
      position: relative;
      display: inline-flex;
      align-items: center;
      small {
        font-size: 16px;
        &.currency {
          position: absolute;
          left: -13px;
          top: 0px;
        }
        &.interval {
          position: absolute;
          right: -30px;
          bottom: 0px;
        }
      }
    }
  }
  &__btn {
    width: 100%;
    margin-top: 18px;
  }
  &__bold {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 300;
    color: #4b5c6e;
    strong {
      color: #0099cc;
      font-weight: 900;
    }
  }
  &__checks {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--check {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 300;
      color: #4b5c6e;
      letter-spacing: -0.4px;
      position: relative;
      display: inline-flex;
      padding-left: 26px;
      &:before {
        position: absolute;
        left: 0;
        content: "\2715";
        font-size: 20px;
        color: red;
        line-height: 22px;
      }
      &.checked {
        &:before {
          content: " ";
          position: absolute;
          left: 0;
          width: 7px;
          height: 14px;
          border-bottom: 2px solid #0099cc;
          border-right: 2px solid #0099cc;
          transform: rotate(30deg);
        }
      }
    }
  }
  &__bottom {
    margin-top: 22px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #4b5c6e;
  }
}
@media (max-width: 1080px) {
  .billing-plans-item {
    width: 100%;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0px;
    }
  }
}
</style>
