export const installDataStorage = {
  init(solutionId) {
    sessionStorage.clear();
    sessionStorage.setItem(solutionId, JSON.stringify({}));
    this.setItem(solutionId, "preinstallConfigCode", null);
    this.setItem(solutionId, "installOptions", {});
    this.setItem(solutionId, "created", new Date().getTime());
  },
  isValid(solutionId) {
    const now = new Date().getTime();
    const created = this.getItem(solutionId, "created");
    return Math.floor(((now - created) / (1000 * 60 * 60)) % 24) < 5;
  },
  setItem(solutionId, item, value) {
    const data = JSON.parse(sessionStorage.getItem(solutionId));
    data[item] = value;
    sessionStorage.setItem(solutionId, JSON.stringify(data));
  },
  getItem(solutionId, item) {
    const data = JSON.parse(sessionStorage.getItem(solutionId));
    return data[item];
  }
};
