const SET_LOADING = (state, value) => {
  state.loading = value;
};

const SET_DATA = (state, value) => {
  state.data = value;
};

export default {
  SET_LOADING,
  SET_DATA
};
