import Vue from "vue";
import {
  Button,
  ButtonGroup,
  Input,
  Form,
  FormItem,
  Checkbox,
  Message,
  Select,
  Option,
  Table,
  TableColumn,
  Tag,
  DatePicker,
  Notification
} from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

locale.use(lang);

Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tag);
Vue.use(DatePicker);

Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
