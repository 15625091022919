<template>
  <div
    class="install-app-success-screen"
    :class="{ 'change-world': isChangWorldPlan }"
  >
    <form-title v-if="isChangWorldPlan">{{
      billingPlan.success.title
    }}</form-title>
    <form-title v-else>Congrats!</form-title>
    <p class="install-app-success-screen__text" v-if="isChangWorldPlan">
      {{ billingPlan.success.description }}
    </p>
    <div v-else>
      <p class="install-app-success-screen__text">
        You're connected! Contact us at
        <a href="mailto:support@lyntonweb.com" target="_top"
          >support@lyntonweb.com</a
        >
        if you have any questions.
      </p>
      <el-button class="install-app-success-screen__btn" @click="goToLogger"
        >View Activity</el-button
      >
    </div>
  </div>
</template>

<script>
import FormTitle from "../../ui/FormTitle.component.vue";
export default {
  components: { FormTitle },
  props: {
    billingPlan: {
      type: Object,
      required: true,
      default() {
        return {
          id: ""
        };
      }
    },
    loggerLinkData: {
      type: Object,
      required: true,
      default() {
        return {
          solutionInstanceId: "",
          portalId: 0
        };
      }
    }
  },
  computed: {
    isChangWorldPlan() {
      return this.billingPlan.id === "plan_classy_change_world";
    }
  },
  methods: {
    goToLogger() {
      const { loggerLinkData } = this;
      this.$router.push({
        name: "solution-instance-logs",
        params: {
          portalId: loggerLinkData.portalId,
          solutionInstanceId: loggerLinkData.solutionInstanceId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.install-app-success-screen {
  padding: 250px 60px 80px;
  background-image: url("../../../assets/images/success-img.svg");
  background-repeat: no-repeat;
  background-position: center 70px;
  &.change-world {
    background-image: url("../../../assets/images/success-img-change-world.svg");
  }
  &__text {
    color: #6b7d90;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    a {
      color: #0099cc;
      text-decoration: none;
    }
  }
  &__btn {
    margin-top: 20px;
  }
}
@media (max-width: 630px) {
  .install-app-success-screen {
    padding: 180px 20px 40px;
    background-position: center 20px;
    &__text {
      margin-top: 20px;
    }
  }
}
</style>
