<template>
  <div class="install-app__steps-wrapper--step">
    <billing-info-form
      ref="billingInfoForm"
      :saved-additional-data="billingStepData"
      :on-credit-card-validation="onCreditCardValidation"
      @start-loading="startLoadingHandler"
      @stop-loading="stopLoadingHandler"
    />
    <el-button
      @click="validateBillingDataHandler"
      :loading="loading"
      :disabled="!creditCardIsValid"
      >Proceed</el-button
    >
  </div>
</template>

<script>
import BillingInfoForm from "../../billing-info/BillingInfoForm.component.vue";
import { installDataStorage } from "../../../utility/marketplaceSessionStorage";
export default {
  components: {
    BillingInfoForm
  },
  props: {
    billingStepData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    solutionId: {
      type: String,
      requred: true,
      default: ""
    }
  },
  beforeCreate() {
    const { skipBillingStep } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (skipBillingStep) {
      this.$router.push({ name: "step-4" });
    }
  },
  data() {
    return {
      creditCardIsValid: false,
      loading: false
    };
  },
  methods: {
    startLoadingHandler() {
      this.loading = true;
    },
    stopLoadingHandler() {
      this.loading = false;
    },
    onCreditCardValidation(isValid) {
      this.creditCardIsValid = isValid;
    },
    validateBillingDataHandler() {
      this.$refs.billingInfoForm.createSource(
        ({ status, data, additionalData }) => {
          if (status === "ok") {
            this.loading = true;
            this.$emit("billing-info-step-finished", {
              source: data.source,
              additionalData,
              stopLoading: this.stopLoadingHandler
            });
          }
        }
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    const { skipBillingStep } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (skipBillingStep) {
      next();
      return;
    }
    this.$emit(
      "save-billing-additional-data",
      this.$refs.billingInfoForm.additionalData
    );
    next();
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  width: 100%;
  margin-top: 40px;
}
</style>
