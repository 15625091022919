import axios from "axios";

export const publicPost = async (url = "", data = {}) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publicGet = async (url = "") => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get = async (url = "", headers = {}) => {
  const config = { headers };
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url = "", data = {}, headers = {}) => {
  const { postData } = data;
  const config = { headers };
  try {
    const response = await axios.post(url, postData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const put = async (url = "", data = null, headers = {}) => {
  const config = { headers };
  try {
    const response = await axios.put(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
