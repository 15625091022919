import { config } from "../config/index";
import { BillingPlan } from "../models/BillingPlan";
import { post, publicGet, put } from "../shared/api";

const baseUrl = config.getBaseUrl();

export const billingService = {
  async getPlans() {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/billing/plans/`;
    const response = await publicGet(url);
    const plans = response.map(item => new BillingPlan(item));
    return plans;
  },
  async getSolutionPlans(id) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/billing/plans/${id}/`;

    try {
      const response = await publicGet(url);
      const plans = response.map(item => new BillingPlan(item));
      return plans;
    } catch (error) {
      throw error;
    }
  },
  async submitBillingInfo({ postData, token }) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/billing/`;
    try {
      const response = await post(url, { postData }, { "x-hubspot": token });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async setBillingFinished({ data, token, solutionId }) {
    const url = `${baseUrl}/api/v1/auth/proxy/integration/solutions/${solutionId}/solution-setup-completed/`;
    try {
      await put(url, data, { "x-hubspot": token });
    } catch (error) {
      throw error;
    }
  }
};
