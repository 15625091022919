export class BillingPlan {
  constructor({ nickname, amount, interval, metadata, id }) {
    this.id = id;
    this.name = nickname;
    this.amount = amount;
    this.interval = interval;
    this.metadata = this.formatMetadata(metadata);
  }

  formatMetadata(data) {
    const metadata = {
      lead: [],
      check: [],
      bold: [],
      bottom: []
    };
    metadata.isQuote = data.__is_quote === "true";
    metadata.submitButtonText = data.__submit_button_text
      ? data.__submit_button_text
      : "Get Started";
    metadata.success = {
      title: data.__conf_title ? data.__conf_title : "",
      description: data.__conf_desc ? data.__conf_desc : ""
    };
    for (let item in data) {
      let ref = item.split("|");
      switch (ref[0]) {
        case "ld":
          metadata.lead.push({ text: ref[2], value: data[item] });
          break;
        case "ck":
          metadata.check.push({
            text: ref[2],
            value: data[item] === "true"
          });
          break;
        case "bd":
          metadata.bold.push({ text: ref[2], value: data[item] });
          break;
        case "bt":
          metadata.bottom.push({ text: ref[2], value: data[item] });
          break;
      }
    }
    return metadata;
  }
}
