<template>
  <div class="install-app__steps-wrapper--step">
    <half-circle-spinner
      v-show="loading"
      class="step__spinner"
      :animation-duration="1000"
      :size="60"
      color="#0099CC"
    />
    <iframe v-show="!loading" :src="installUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
import { installDataStorage } from "../../../utility/marketplaceSessionStorage";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    installUrl: {
      type: String,
      required: true,
      default: ""
    },
    trayConfigReady: {
      type: Boolean,
      required: true,
      default: false
    },
    solutionId: {
      type: String,
      requred: true,
      default: ""
    }
  },
  beforeCreate() {
    const { skipTrayConfigWizard } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (skipTrayConfigWizard) {
      this.$emit("trigger-workflows");
    } else {
      this.$emit("fetch-install-url");
    }
  },
  computed: {
    loading() {
      return !this.trayConfigReady;
    }
  }
};
</script>

<style lang="scss" scoped>
.install-app__steps-wrapper--step {
  height: 700px;
  .step__spinner {
    margin: 320px auto;
  }
  iframe {
    width: 100%;
    height: 700px;
  }
}
</style>
