<template>
  <div class="install-app__steps-wrapper--plans-step">
    <billing-plans
      @billing-plan-selected="billingPlansSelectedHandler"
      :solutionId="solutionId"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { installDataStorage } from "../../../utility/marketplaceSessionStorage";
import BillingPlans from "../billing-plans/BillingPlans.component.vue";
export default {
  components: {
    BillingPlans
  },
  props: {
    solutionId: {
      type: String,
      requred: true,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      billingPlans: "billing/solutionData"
    }),
    noBillingPlans() {
      return this.billingPlans.length === 0;
    }
  },
  beforeCreate() {
    const { skipBillingStep } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (skipBillingStep) {
      this.$router.push({ name: "step-3" });
    }
  },
  created() {
    if (this.noBillingPlans) {
      this.$router.push({ name: "step-3" });
    }
  },
  methods: {
    billingPlansSelectedHandler(data) {
      this.$emit("billing-plans-step-finished", data);
    }
  }
};
</script>
