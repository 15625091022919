<template>
  <el-form
    :model="additionalData"
    :validate-on-rule-change="false"
    :rules="$options.billingFormRules"
    ref="billingForm"
  >
    <form-title>Billing information</form-title>
    <el-form-item label="Name on the card*" prop="name">
      <el-input
        v-model="additionalData.name"
        :placeholder="$options.defaultPlaceholder"
      ></el-input>
    </el-form-item>
    <div class="credit-card-inputs" :class="{ complete }">
      <div class="credit-card-inputs__card-number">
        <label class="credit-card-inputs__label">Credit or debit card*</label>
        <card-number
          class="credit-card-inputs__stripe-element card-number"
          ref="cardNumber"
          :stripe="stripe"
          :options="$options.stripeOptions"
          @change="numberChange($event)"
        />
      </div>
      <div class="credit-card-inputs__expiry-cvc">
        <div>
          <label class="credit-card-inputs__label">Expiration*</label>
          <card-expiry
            class="credit-card-inputs__stripe-element card-expiry"
            ref="cardExpiry"
            :stripe="stripe"
            :options="$options.stripeOptions"
            @change="expiryChange($event)"
          />
        </div>
        <div>
          <label class="credit-card-inputs__label">CVC*</label>
          <card-cvc
            class="credit-card-inputs__stripe-element card-cvc"
            ref="cardCvc"
            :stripe="stripe"
            :options="$options.stripeOptions"
            @change="cvcChange($event)"
          />
        </div>
      </div>
      <div class="credit-card-inputs__error">{{ paymentInformationError }}</div>
    </div>
    <el-form-item label="Billing address*" prop="addressLine1">
      <el-input
        v-model="additionalData.addressLine1"
        :placeholder="$options.defaultPlaceholder"
      ></el-input>
    </el-form-item>
    <div class="row">
      <el-form-item
        label="Country*"
        class="el-form-item__select"
        prop="addressCountry"
      >
        <el-select
          v-model="additionalData.addressCountry"
          @change="countrySelectHandler"
          placeholder="Select country"
          filterable
        >
          <el-option
            v-for="country in $options.countries"
            :key="country.countryShort"
            :label="`${country.countryName}`"
            :value="country.countryShort"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="State*"
        :class="{ disabled: stateSelectDisabled }"
        prop="addressState"
        class="el-form-item__select"
      >
        <el-select
          v-model="additionalData.addressState"
          :disabled="stateSelectDisabled"
          :placeholder="$options.defaultPlaceholder"
          filterable
        >
          <el-option
            v-for="state in stateSelectOptions"
            :key="state.stateName"
            :label="state.stateName"
            :value="state.stateShort"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item label="Zip*" prop="addressZip">
        <el-input
          v-model="additionalData.addressZip"
          :placeholder="$options.defaultPlaceholder"
        ></el-input>
      </el-form-item>
      <el-form-item label="City*" prop="addressCity">
        <el-input
          v-model="additionalData.addressCity"
          :placeholder="$options.defaultPlaceholder"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item label="Mobile phone*" prop="phone">
      <el-input
        v-model="additionalData.phone"
        :placeholder="$options.defaultPlaceholder"
      >
        <template slot="prepend">+{{ selectedCountryCode }}</template>
      </el-input>
    </el-form-item>
    <!-- <el-checkbox v-model="terms"></el-checkbox> <span>I agree to the <a href="" target="_blank">Terms and Conditions</a></span> -->
  </el-form>
</template>

<script>
import {
  CardCvc,
  CardExpiry,
  CardNumber,
  createSource
} from "vue-stripe-elements-plus";
import { config } from "../../config/index";
import { countries } from "../../utility/countries";
import { validationRules } from "../../utility/validationRules";
import FormTitle from "../ui/FormTitle.component.vue";
export default {
  countries,
  billingFormRules: validationRules.getBillingFormRules({
    phone: "",
    state: false
  }),
  stripeOptions: {
    style: {
      base: {
        color: "#606266",
        fontFamily: "Avenir, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        "::placeholder": {
          color: "#c0c4cc"
        }
      },
      invalid: {
        color: "#555555"
      }
    }
  },
  defaultPlaceholder: "Type here",
  props: {
    onCreditCardValidation: {
      type: Function,
      default: function() {
        return {};
      }
    },
    savedAdditionalData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  components: { CardNumber, CardExpiry, CardCvc, FormTitle },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
    terms() {
      this.update();
    }
  },
  data() {
    return {
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      terms: false,
      numberError: "",
      expiryError: "",
      cvcError: "",
      stripe: config.stripeKey(),
      additionalData: {
        name: "",
        addressLine1: "",
        addressCity: "",
        addressCountry: "",
        addressState: null,
        addressZip: "",
        phone: ""
      },
      selectedCountryCode: null,
      stateSelectDisabled: true,
      stateSelectOptions: []
    };
  },
  computed: {
    ownerData() {
      return {
        owner: {
          name: this.additionalData.name,
          address: {
            line1: this.additionalData.addressLine1,
            city: this.additionalData.addressCity,
            postal_code: this.additionalData.addressZip,
            state: this.additionalData.addressState,
            country: this.additionalData.addressCountry
          },
          phone: `+${this.selectedCountryCode}${this.additionalData.phone}`
        }
      };
    },
    paymentInformationError() {
      return this.numberError || this.expiryError || this.cvcError || "";
    }
  },
  mounted() {
    for (let key in this.savedAdditionalData) {
      this.additionalData[key] = this.savedAdditionalData[key];
    }
    this.additionalData.addressCountry =
      this.additionalData.addressCountry || "US";
    this.countrySelectHandler(this.additionalData.addressCountry);
  },
  methods: {
    numberChange(event) {
      this.number = event.complete;
      if (event.error) {
        this.numberError = event.error.message || "Invalid credit card number";
      } else {
        this.numberError = "";
      }
    },
    expiryChange(event) {
      this.expiry = event.complete;
      if (event.error) {
        this.expiryError = event.error.message || "Invalid expiration date";
      } else {
        this.expiryError = "";
      }
    },
    cvcChange(event) {
      this.cvc = event.complete;
      if (event.error) {
        this.cvcError = event.error.message || "Invalid security code";
      } else {
        this.cvcError = "";
      }
    },
    update() {
      // this.complete = this.number && this.expiry && this.cvc && this.terms;
      this.complete = this.number && this.expiry && this.cvc;
      this.onCreditCardValidation(this.complete);

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    createSource(callback) {
      this.$refs["billingForm"].validate(async valid => {
        if (valid && this.complete) {
          try {
            this.$emit("start-loading");
            const data = await createSource(this.ownerData);
            callback({
              status: "ok",
              data: data,
              additionalData: {
                ...this.additionalData,
                phone: `+${this.selectedCountryCode}${this.additionalData.phone}`
              }
            });
          } catch (error) {
            this.$emit("stop-loading");
            callback({ status: "error", error: error });
          }
        } else {
          callback({ status: "error", error: "Invalid form data" });
        }
      });
    },
    countrySelectHandler(val) {
      this.stateSelectOptions.length = 0;
      this.additionalData.addressState = null;
      this.$refs["billingForm"].clearValidate();
      const countryData = this.$options.countries.find(
        country => country.countryShort === val
      );
      this.selectedCountryCode = countryData.countryCode;
      this.$options.billingFormRules = validationRules.getBillingFormRules({
        state: countryData.areaCodes ? true : false,
        phone: countryData.countryShort
      });
      this.stateSelectDisabled = countryData.areaCodes ? false : true;
      if (!this.stateSelectDisabled) {
        this.stateSelectOptions = [...countryData.areaCodes];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  > div {
    width: 190px;
  }
}
.credit-card-inputs {
  text-align: left;
  margin-bottom: 22px;
  &__error {
    color: #f56c6c;
    font-size: 12px;
    padding-top: 4px;
  }
  &__label {
    font-size: 14px;
    line-height: 30px;
    color: #4b5c6e;
    font-weight: 700;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
  }
  &__stripe-element {
    border: 1px solid #cbd6e2;
    border-radius: 3px;
    background-color: #f6f8fa;
    height: 40px;
    padding: 12px 15px;
  }
  &__card-number {
    margin-bottom: 22px;
    background-image: url("../../assets/images/cards.svg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 160px;
  }
  &__expiry-cvc {
    display: flex;
    justify-content: space-between;
    > div {
      width: 190px;
    }
  }
}
@media (max-width: 630px) {
  .row {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .el-form-item__content::v-deep {
      width: 100%;
      .el-select {
        width: 100%;
      }
    }
  }
}
@media (max-width: 510px) {
  .credit-card-inputs {
    &__expiry-cvc {
      > div {
        width: calc(50% - 10px);
      }
    }
  }
}
@media (max-width: 410px) {
  .credit-card-inputs {
    &__card-number {
      background-size: 90px;
    }
  }
}
</style>
