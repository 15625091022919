<template>
  <div class="billing-plans">
    <billing-plans-item
      v-for="plan in filteredPlans"
      :key="plan.id"
      :plan-data="plan"
      @plan-selected="planSelectedHandler"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { installDataStorage } from "../../../utility/marketplaceSessionStorage";
import BillingPlansItem from "./BillingPlansItem.component.vue";
export default {
  components: {
    BillingPlansItem
  },
  props: {
    solutionId: {
      type: String,
      requred: true,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      billingPlans: "billing/solutionData"
    }),
    filteredPlans() {
      const limitedOptionsArray = this.$options.filteredPlans;
      const billingPlans = this.billingPlans;

      if (!limitedOptionsArray?.length) {
        return billingPlans;
      }
      const filteredPlans = billingPlans.filter(plan =>
        limitedOptionsArray.includes(plan.id)
      );

      return filteredPlans.length ? filteredPlans : billingPlans;
    }
  },
  created() {
    const { limitedSubscriptionOptions } = installDataStorage.getItem(
      this.$options.propsData.solutionId,
      "installOptions"
    );
    if (
      limitedSubscriptionOptions &&
      typeof limitedSubscriptionOptions === "string" &&
      limitedSubscriptionOptions.length
    ) {
      const limitedOptionsArray = limitedSubscriptionOptions.split(",");

      this.$options.filteredPlans = [...limitedOptionsArray];
    } else {
      this.$options.filteredPlans = null;
    }
  },
  methods: {
    planSelectedHandler(data) {
      this.$emit("billing-plan-selected", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.billing-plans {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 1080px) {
  .billing-plans {
    flex-direction: column;
  }
}
</style>
