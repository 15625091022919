export default [
  {
    title: "Classy API Credentials",
    description: "In your Classy admin dashboard...",
    data: [
      {
        type: "text",
        value: "test",
        label: "Client ID",
        propertyName: "client_id",
        required: true,
        valid: true,
        id: "1"
      },
      {
        type: "text",
        value: "",
        label: "Client Secret",
        propertyName: "client_secret",
        required: true,
        valid: true,
        id: "2"
      },
      {
        type: "text",
        value: "",
        label: "Classy Org ID",
        propertyName: "classy_org_id",
        required: true,
        valid: true,
        id: "3"
      }
    ]
  },
  {
    title: "How far back should we sync your data?",
    description: "Choose a date and time below.",
    data: [
      {
        type: "dateTimePicker",
        value: new Date(),
        label: "",
        propertyName: "classy_sync_time",
        required: true,
        valid: true,
        id: "4"
      }
    ]
  }
];
