import { billingService } from "./../../../services/billingService";

const fetchData = async context => {
  if (context.getters.data.length === 0) {
    context.commit("SET_LOADING", true);
    const data = await billingService.getPlans();
    context.commit("SET_DATA", data);
    context.commit("SET_LOADING", false);
  }
};

const fetchSolutionPlansData = async (context, id) => {
  context.commit("SET_LOADING", true);
  try {
    const data = await billingService.getSolutionPlans(id);
    context.commit("SET_SOLUTION_DATA", data);
  } catch (error) {
    throw error;
  } finally {
    context.commit("SET_LOADING", false);
  }
};

const submitBillingInfo = async (context, data) => {
  await context.commit("SET_LOADING", true);
  const {
    source,
    billingPlan,
    metadata,
    solutionInstanceId,
    token,
    portalId
  } = data;
  const payload = {
    token,
    postData: {
      source,
      billing_plan: billingPlan,
      portal_id: portalId,
      solution_instance_id: solutionInstanceId,
      metadata: {
        first_name: metadata.firstName,
        last_name: metadata.lastName,
        role: metadata.role,
        company_name: metadata.companyName,
        website_url: metadata.companyWebsiteUrl
      }
    }
  };
  try {
    const billingResponse = await billingService.submitBillingInfo(payload);
    const { id, customer } = billingResponse;
    await context.commit("SET_BILLING_RESPONSE_DATA", { id, customer });
  } catch (error) {
    throw error;
  }
  await context.commit("SET_LOADING", false);
};

const triggerWorkflows = async (context, data) => {
  context.commit("SET_LOADING", true);
  const { token, solutionInstanceId, preinstallConfigCode } = data;
  const { id, customer } = context.getters.billingResponseData;
  const billingFinishedPayload = {
    token,
    data: {
      stripe_cus_id: customer,
      stripe_sub_id: id,
      preinstall_config_code: preinstallConfigCode
    },
    solutionId: solutionInstanceId
  };
  try {
    await billingService.setBillingFinished(billingFinishedPayload);
  } catch (error) {
    throw error;
  } finally {
    context.commit("SET_LOADING", false);
  }
};

export default {
  fetchData,
  fetchSolutionPlansData,
  submitBillingInfo,
  triggerWorkflows
};
