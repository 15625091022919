import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";

Sentry.init({
  Vue,
  dsn:
    "https://be969df5fbb7460bae9b824004b43be7@o217597.ingest.sentry.io/5503140",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
