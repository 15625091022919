<template>
  <div class="tray-input">
    <el-input
      v-if="inputData.type === 'text'"
      v-model="inputValue"
      @input="inputDataChanged"
      :class="{ invalid: !inputData.valid }"
    >
      <template slot="prepend">{{ inputData.label }}</template>
    </el-input>
    <el-date-picker
      v-if="inputData.type === 'dateTimePicker'"
      v-model="inputValue"
      type="datetime"
      placeholder="Select date and time"
      :clearable="false"
      @change="inputDataChanged"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    inputData: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      inputValue: null
    };
  },
  created() {
    this.inputValue = this.inputData.value;
  },
  methods: {
    inputDataChanged() {
      const { id } = this.inputData;
      const value = this.inputValue;
      this.$emit("input-data-changed", { id, value });
    }
  }
};
</script>

<style lang="scss" scoped>
.tray-input {
  margin-top: 20px;
  .el-input {
    &.invalid {
      border: 1px solid red;
    }
  }
}
</style>
