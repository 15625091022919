export class Log {
  constructor({
    log_level,
    time_stamp,
    entity,
    message,
    organization,
    hubspot_solution
  }) {
    this.status = log_level;
    this.time = this.formatTime(time_stamp);
    this.entity = {
      ...entity,
      name: entity.name || "-",
      email: entity.email ? entity.email.toLowerCase() : "-",
      url: this.formatUrl(entity, organization),
      type: entity.type || "-"
    };
    this.message = message ? message : "-";
    this.messagePreview = this.formatMessagePreview(message);
    this.operation = hubspot_solution.operation || "-";
  }

  formatTime(timeStamp) {
    const localTime = new Date(timeStamp);
    return `${localTime.toLocaleDateString()} ${localTime.toLocaleTimeString()}`;
  }

  formatUrl(entity, organization) {
    if (entity.hubspot_id) {
      if (entity.type.toUpperCase() === "DEAL") {
        return `https://app.hubspot.com/sales/${
          organization.hubspot_portal_id
        }/${entity.type.toLowerCase()}/${entity.hubspot_id}/`;
      } else if (entity.type.toUpperCase() === "COMPANY") {
        return `https://app.hubspot.com/companies/${
          organization.hubspot_portal_id
        }/${entity.type.toLowerCase()}/${entity.hubspot_id}/`;
      } else {
        return `https://app.hubspot.com/${entity.type.toLowerCase()}s/${
          organization.hubspot_portal_id
        }/${entity.type.toLowerCase()}/${entity.hubspot_id}/`;
      }
    }
    return "";
  }

  formatMessagePreview(message) {
    if (!message) return "-";
    if (message.length < 45) {
      return message;
    } else {
      return message.slice(0, 44) + "...";
    }
  }
}
